import React, { useRef, useState } from "react";
import DOMPurify from "dompurify";
import { createAvatar } from "@dicebear/core";
import { identicon } from "@dicebear/collection";
import { Box, Snackbar, Alert } from "@mui/material";
import { useUploadProfileImageMutation } from "shared/api";
import CameraAltIcon from "@mui/icons-material/CameraAlt"; // Import Material UI camera icon

interface IdenticonAvatarProps {
  seedValue: string | undefined;
  sizeValue?: number;
  profileImageUrl?: string;
  allowUpload?: boolean;
}

export const IdenticonAvatar: React.FC<IdenticonAvatarProps> = ({
  seedValue,
  sizeValue,
  profileImageUrl,
  allowUpload = false,
}) => {
  const inputFileRef = useRef<HTMLInputElement | null>(null);
  const [uploadProfileImage] = useUploadProfileImageMutation();
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const handleSnackbarClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };

  const handleAvatarClick = () => {
    if (allowUpload && inputFileRef.current) {
      inputFileRef.current.click();
    }
  };

  const handleFileChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      const file = e.target.files[0];
      const userId = seedValue ? parseInt(seedValue, 10) : 0;
      const maxFileSize = 2.5 * 1024 * 1024; // 2.5 MB

      // Check file size (2.5 MB = 2621440 bytes)
      if (file.size >= maxFileSize) {
        setErrorMessage("File size exceeds 2.5 MB");
        setSnackbarOpen(true);
        return;
      }

      // Check file type
      const allowedExtensions = [
        "jpg",
        "jpeg",
        "png",
        "gif",
        "heic",
        "heif",
        "bmp",
        "tiff",
        "webp",
      ];
      const fileExtension = file.name.split(".").pop()?.toLowerCase();
      if (!fileExtension || !allowedExtensions.includes(fileExtension)) {
        setErrorMessage("Invalid file type");
        setSnackbarOpen(true);
        return;
      }

      try {
        await uploadProfileImage({ file, userId });
        console.log("Profile image uploaded successfully");
      } catch (error) {
        console.error("Error uploading profile image:", error);
        setErrorMessage("Error uploading profile image");
        setSnackbarOpen(true);
      } finally {
        // hard refresh by navigating using window.location
        window.location.reload();
      }
    }
  };

  const avatar = createAvatar(identicon, {
    seed: seedValue ? seedValue.toString() : "",
    scale: 75,
    radius: 50,
    backgroundColor: ["b6e3f4", "c0aede", "d1d4f9"],
    backgroundType: ["gradientLinear", "solid"],
    size: sizeValue,
  });

  const svg = avatar.toString();

  return (
    <Box
      position="relative"
      onClick={handleAvatarClick}
      style={{
        cursor: allowUpload ? "pointer" : "default",
        display: "inline-block",
      }}
    >
      {profileImageUrl ? (
        <img
          src={profileImageUrl}
          alt="Profile"
          style={{
            width: "40px",
            height: "40px",
            borderRadius: "50%",
            objectFit: "cover",
            marginRight: "16px",
            marginTop: "4px",
          }}
        />
      ) : (
        <div
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(svg) }}
          style={{
            width: "40px",
            height: "40px",
            marginRight: "16px",
            marginTop: "4px",
          }}
        />
      )}

      {/* Conditionally render the camera icon in the bottom-right corner */}
      {allowUpload && (
        <>
          <input
            ref={inputFileRef}
            type="file"
            accept=".jpg,.jpeg,.png,.gif,.heic,.heif,.bmp,.tiff,.webp"
            style={{ display: "none" }}
            onChange={handleFileChange}
          />
          <Box
            position="absolute"
            bottom={0}
            right={0}
            bgcolor="white"
            borderRadius="50%"
            padding="4px"
            boxShadow="0 0 5px rgba(0,0,0,0.3)"
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <CameraAltIcon fontSize="small" />
          </Box>
        </>
      )}

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity="error"
          sx={{ width: "100%" }}
        >
          {errorMessage}
        </Alert>
      </Snackbar>
    </Box>
  );
};
