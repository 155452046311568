import { FC } from "react";
import {
  Box,
  Button,
  Drawer,
  FormControlLabel,
  Switch,
  Typography,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";
import { Colors } from "shared/themes";
import { ModernProgramBuilderResponse } from "shared/api";

interface ModernProgramBuilderSettingsSidebarProps {
  isDrawerOpen: boolean;
  handleCloseDrawer: () => void;
  simplifiedView: boolean;
  setSimplifiedView: (value: boolean) => void;
  duplicateView: boolean;
  setDuplicateView: (value: boolean) => void;
  progressionView: boolean;
  setProgressionView: (value: boolean) => void;
  showNotes: boolean;
  setShowNotes: (value: boolean) => void;
  showLogs: boolean;
  setShowLogs: (value: boolean) => void;
  selectedWorkoutProgram: ModernProgramBuilderResponse | undefined;
}

const labelStyles = {
  mt: 1,
  "& span": {
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "14px",
    lineHeight: "20px",
    color: Colors.oxford[1100],
  },
};

const ModernProgramBuilderSettingsSidebar: FC<
  ModernProgramBuilderSettingsSidebarProps
> = ({
  isDrawerOpen,
  handleCloseDrawer,
  simplifiedView,
  setSimplifiedView,
  duplicateView,
  setDuplicateView,
  progressionView,
  setProgressionView,
  showNotes,
  setShowNotes,
  showLogs,
  setShowLogs,
  selectedWorkoutProgram,
}) => {
  return (
    <Drawer anchor="right" open={isDrawerOpen} onClose={handleCloseDrawer}>
      <Box
        sx={{
          mt: 8,
          width: "25vw",
          height: "40vh",
          display: "flex",
          flexDirection: "column",
          padding: "16px 24px 32px",
          gap: "24px",
          zIndex: 9999,
        }}
      >
        <FormControlLabel
          control={
            <Switch
              size="small"
              onChange={() => setSimplifiedView(!simplifiedView)}
              checked={simplifiedView}
            />
          }
          label="Simple"
          title="When simple view is enabled, you can edit the program in a simplified format. Disabling simple view will allow you to edit each week individually."
          sx={labelStyles}
        />
        <FormControlLabel
          control={
            <Switch
              size="small"
              onChange={() => setDuplicateView(!duplicateView)}
              checked={duplicateView}
            />
          }
          label="Duplicate Weeks"
          title="When enabled, this will duplicate the changes made to week 1 / base week to other weeks."
          sx={labelStyles}
        />
        <FormControlLabel
          control={
            <Switch
              size="small"
              onChange={() => setShowNotes(!showNotes)}
              checked={showNotes}
            />
          }
          label="Notes"
          title="When enabled, notes for each exercise will be displayed."
          sx={labelStyles}
        />
        {selectedWorkoutProgram?.workoutProgramType === "UserProgram" && (
          <FormControlLabel
            control={
              <Switch
                size="small"
                onChange={() => setShowLogs(!showLogs)}
                checked={showLogs}
              />
            }
            label="Logs"
            title="When enabled, completed logs for each exercise will be displayed."
            sx={labelStyles}
          />
        )}
        <Button
          variant="outlined"
          color="primary"
          onClick={handleCloseDrawer}
          sx={{
            textTransform: "none",
            textDecoration: "none",
          }}
        >
          Close
        </Button>
      </Box>
      <Box
        sx={{
          mt: 4,
          ml: 4,
          width: "90%",
          maxWidth: "500px",
        }}
      >
        <Typography variant="h6" sx={{ color: Colors.oxford[1100], mb: 2 }}>
          Progression
        </Typography>
        <Typography
          variant="body1"
          sx={{
            color: Colors.oxford[1100],
            fontFamily: "Inter",
            fontStyle: "normal",
            fontWeight: 400,
            fontSize: "14px",
            lineHeight: "20px",
          }}
        >
          <strong>Progression</strong> is a method to incrementally increase the
          weight or intensity for each exercise. Here&rsquo;s how to define
          progression:
        </Typography>

        {/* RPE Progression Section */}
        <Box sx={{ mt: 2 }}>
          <Typography
            variant="subtitle1"
            sx={{ fontWeight: 600, color: Colors.oxford[1100] }}
          >
            RPE Progression
          </Typography>
          <List>
            <ListItem disablePadding>
              <ListItemText primary="[Type] [Value] [Frequency]" />
            </ListItem>
          </List>
        </Box>

        {/* 1RM Progression Section */}
        <Box sx={{ mt: 2 }}>
          <Typography
            variant="subtitle1"
            sx={{ fontWeight: 600, color: Colors.oxford[1100] }}
          >
            1RM Progression
          </Typography>
          <List>
            <ListItem disablePadding>
              <ListItemText primary="[Type] [1RM] [Value] [Frequency]" />
            </ListItem>
          </List>
        </Box>
        <Box sx={{ mt: 2 }}>
          <Typography
            variant="subtitle1"
            sx={{ fontWeight: 600, color: Colors.oxford[1100] }}
          >
            Syntax
          </Typography>
          <List>
            <ListItem>
              <Typography variant="body2">
                <strong>Type:</strong> Determines the basis of progression.
                <List component="div" sx={{ pl: 2 }}>
                  <ListItem disablePadding>
                    - <strong>rpe</strong> (Rate of Perceived Exertion)
                  </ListItem>
                  <ListItem disablePadding>
                    - <strong>1rm</strong> (One-Rep Max)
                  </ListItem>
                </List>
              </Typography>
            </ListItem>
            <ListItem>
              <Typography variant="body2">
                <strong>1RM:</strong> Specifies the one-rep max value.
                <List component="div" sx={{ pl: 2 }}>
                  <ListItem disablePadding>
                    - A specific weight (e.g., &quot;100kg&quot;)
                  </ListItem>
                </List>
              </Typography>
            </ListItem>
            <ListItem>
              <Typography variant="body2">
                <strong>Value:</strong> Specifies the increment value.
                <List component="div" sx={{ pl: 2 }}>
                  <ListItem disablePadding>
                    - A specific number (e.g., &quot;7&quot;)
                  </ListItem>
                  <ListItem disablePadding>
                    - A range (e.g., &quot;7-8&quot;)
                  </ListItem>
                </List>
              </Typography>
            </ListItem>
            <ListItem>
              <Typography variant="body2">
                <strong>Frequency:</strong> Defines how often progression
                occurs.
                <List component="div" sx={{ pl: 2 }}>
                  <ListItem disablePadding>
                    - <strong>e/w</strong> (every week)
                  </ListItem>
                  <ListItem disablePadding>
                    - <strong>e/2w</strong> (every 2 weeks)
                  </ListItem>
                  <ListItem disablePadding>
                    - <strong>e/3w</strong> (every 3 weeks)
                  </ListItem>
                </List>
              </Typography>
            </ListItem>
          </List>
        </Box>

        {/* Examples Section */}
        <Box sx={{ mt: 2 }}>
          <Typography
            variant="subtitle1"
            sx={{ fontWeight: 600, color: Colors.oxford[1100] }}
          >
            Examples
          </Typography>
          <List>
            {/* RPE Example */}
            <ListItem>
              <Typography variant="body2">
                <strong>&quot;rpe 1 e/w&quot;</strong>
                <br />- Increases RPE by 1 every week.
              </Typography>
            </ListItem>
            {/* 1RM Example */}
            <ListItem>
              <Typography variant="body2">
                <strong>&quot;1rm 100kg 2.5% e/w&quot;</strong>
                <br />- Increases the 1RM by 2.5% each week.
              </Typography>
            </ListItem>
            {/* RPE every 2 weeks Example */}
            <ListItem>
              <Typography variant="body2">
                <strong>&quot;rpe 0.5 e/2w&quot;</strong>
                <br />- Increases RPE by 0.5 every 2 weeks.
              </Typography>
            </ListItem>
          </List>
        </Box>
      </Box>
    </Drawer>
  );
};

export { ModernProgramBuilderSettingsSidebar };
