/* eslint-disable */
import React, {
  FC,
  useState,
  useEffect,
  useRef,
  KeyboardEvent,
  useCallback,
  useMemo,
} from "react";
import { produce } from "immer";
import { useVirtualizer } from "@tanstack/react-virtual";
import dayjs from "dayjs";
import { useParams } from "react-router-dom";
import {
  Typography,
  useMediaQuery,
  Grid,
  IconButton,
  Tab,
  FormControlLabel,
  Switch,
  TextField,
  Card,
  Tooltip,
  Autocomplete,
  Button,
} from "@mui/material";
import { Box, Stack } from "@mui/system";
import { styled } from "@mui/material/styles";
import { useTypedDispatch, useTypedSelector } from "shared/stores";
import { setIsModified } from "shared/stores/workoutBuilder/slice";
import { sharedStyles } from "shared/themes/shared/styles";
import {
  ModernProgramBuilderResponse,
  useGetModernWorkoutProgramsQuery,
  useGetWorkoutProgramGroupItemsQuery,
  useUpdateDraftWorkoutProgramMutation,
  useCreateNewVersionOfExistingWorkoutProgramMutation,
  useCompleteWorkoutProgramAndShareMutation,
  WorkoutProgramGroupItem,
  WorkoutProgramGroupItemParams,
  useCheckStripeIntegrationQuery,
  CompleteAndShareModernProgramBuilderRequest,
  useGetExercisesLibraryQuery,
  ModernProgramBuilderExercise,
} from "shared/api";
import { ClientInsights } from "pages/Users/ClientInsights";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import LoadingButton from "@mui/lab/LoadingButton";
import { TabContext, TabList } from "@mui/lab";
import { Colors } from "shared/themes";
import { IdenticonAvatar } from "shared/ui/IdenticonAvatar";
import { TrainingInsightsContent } from "pages/TrainingInsights/TrainingInsightsContent";
import { SaveNewVersionModal } from "./SaveNewVersionModal";
import { SavingProgramModal } from "./SavingProgramModal";
import { CompleteAndShareModal } from "./CompleteAndShareModal";
import { validationRules } from "./validationRules";
import { ModernProgramBuilderSettingsSidebar } from "./ModernProgramBuilderSettingsSidebar";

interface ParsedProgression {
  type: "RPE" | "RIR" | "1RM";
  value: number;
  frequency: number;
  oneRmValue?: number;
  unit?: string;
}

const columnWidths: { [key: string]: number } = {
  order: 60,
  exerciseName: 300,
  sets: 80,
  reps: 80,
  progression: 160,
  intensity: 120,
  load: 120,
  supersetGroup: 100,
  rest: 120,
  notes: 200,
  readOnlyCompletedLog: 200,
  actions: 40, // For delete icon
};

// Styled Components
const UserInfoCard = styled(Card)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(2),
  marginBottom: theme.spacing(3),
  backgroundColor: Colors.gray[50],
  boxShadow: "none",
}));

const UserDetails = styled(Stack)(({ theme }) => ({
  marginLeft: theme.spacing(2),
}));

const ProgramInfo = styled(Box)(({ theme }) => ({
  marginTop: theme.spacing(1),
  display: "flex",
  alignItems: "center",
  color: Colors.gray[600],
}));

const StyledTextField = styled(TextField)(({ theme }) => ({
  backgroundColor: "#fff",
  borderRadius: theme.shape.borderRadius,
}));

const StyledInput = styled("input")({
  width: "100%",
  height: "100%",
  padding: "8px",
  boxSizing: "border-box",
  border: "none",
  outline: "none",
  backgroundColor: "transparent",
  "&:focus": {
    outline: "2px solid #1976d2",
  },
});

const getFieldKey = (
  weekIndex: number,
  dayIndex: number,
  exerciseIndex: number,
  fieldName: string
) => {
  return `week-${weekIndex}_day-${dayIndex}_exercise-${exerciseIndex}_field-${fieldName}`;
};

// Updated InputCell Component
const InputCell = ({
  initialValue,
  onValueChange,
  inputRef,
  style,
  fieldNames,
  weekIndex,
  dayIndex,
  exerciseIndex,
  fieldName,
  inputRefs,
  maxIndices,
  isSelected,
  isEditing, // Indicates if the cell is in edit mode
  onClick,
  onBlurEditing, // Function to clear editing state in parent
  onEnterEditMode, // Handler to enter edit mode
}: {
  initialValue: string;
  onValueChange: (value: string) => void;
  inputRef: React.Ref<any>;
  style: React.CSSProperties;
  fieldNames: string[];
  weekIndex: number;
  dayIndex: number;
  exerciseIndex: number;
  fieldName: string;
  inputRefs: React.MutableRefObject<{ [key: string]: HTMLInputElement | null }>;
  maxIndices: {
    weeks: number;
    daysPerWeek: number[];
    exercisesPerDay: number[][];
  };
  isSelected: boolean;
  isEditing: boolean;
  onClick: () => void;
  onBlurEditing: () => void;
  onEnterEditMode: () => void; // New prop for entering edit mode
}) => {
  const [value, setValue] = useState(initialValue || "");
  const [isValid, setIsValid] = useState(true);
  const [cellErrorMessage, setCellErrorMessage] = useState("");
  const clickTimeout = useRef<ReturnType<typeof setTimeout> | null>(null); // Track the click timeout

  // Update local state when initialValue changes
  useEffect(() => {
    setValue(initialValue || "");
  }, [initialValue]);

  // Validate the value when it changes
  useEffect(() => {
    const validate = validationRules[fieldName];
    if (validate) {
      const { isValid, cellErrorMessage } = validate(value);
      setIsValid(isValid);
      setCellErrorMessage(cellErrorMessage);
    } else {
      setIsValid(true);
      setCellErrorMessage("");
    }
  }, [value, fieldName]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value);
  };

  const handleBlur = () => {
    if (value !== initialValue) {
      onValueChange(value);
    }
    onBlurEditing(); // Notify parent to clear editing state
  };

  const handleKeyDown = (e: React.KeyboardEvent) => {
    if (e.key === "Enter") {
      e.preventDefault();
      (e.currentTarget as HTMLElement).blur();
    }
  };

  const inputStyles: React.CSSProperties = {
    ...style,
    border: isEditing ? "1px solid #ccc" : "none",
    backgroundColor: isValid ? "transparent" : "#ffe6e6", // Light red background if invalid
    color: "#212529",
  };

  const handleClick = (e: React.MouseEvent) => {
    if (clickTimeout.current) {
      // If a second click happens within the timeout, it's a double click
      clearTimeout(clickTimeout.current);
      clickTimeout.current = null;
      onEnterEditMode(); // Trigger the double-click action (enter edit mode)
    } else {
      // If it's the first click, set a timeout to detect a double click
      clickTimeout.current = setTimeout(() => {
        clickTimeout.current = null;
        onClick(); // Trigger the single-click action
      }, 300); // Timeout for detecting double-clicks
    }
  };

  if (isEditing) {
    return (
      <StyledInput
        type="text"
        value={value}
        onChange={handleChange}
        onBlur={handleBlur}
        ref={inputRef}
        style={inputStyles}
        onClick={(e) => e.stopPropagation()}
        onKeyDown={handleKeyDown}
      />
    );
  }
  const cellContent = (
    <Typography
      onClick={handleClick} // Handle clicks and double-clicks
      sx={{
        cursor: "pointer",
        padding: "8px",
        width: "100%",
        height: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-start",
        boxSizing: "border-box",
        backgroundColor: isValid ? "transparent" : "#ffe6e6",
      }}
    >
      {value}
    </Typography>
  );

  if (!isValid && cellErrorMessage !== "") {
    return (
      <Tooltip title={cellErrorMessage} placement="top">
        {cellContent}
      </Tooltip>
    );
  }
  return cellContent;
};

// Main Component
const ModernProgramBuilder: FC = () => {
  const params = useParams();

  const [openSaveVersionModal, setOpenSaveVersionModal] =
    useState<boolean>(false);

  const [openCompleteAndShareModal, setOpenCompleteAndShareModal] =
    useState<boolean>(false);

  const [invoiceRequested, setInvoiceRequested] = useState(false);

  const [invoiceDueDate, setInvoiceDueDate] = useState<string>(
    dayjs().add(14, "day").format("YYYY-MM-DDTHH:mm:ss[Z]")
  );

  const handleInvoiceRequestedDateChange = (date: string | null) => {
    setInvoiceDueDate(date ?? invoiceDueDate);
  };

  const workoutProgramId = Number(params.workoutProgramId);

  const [isDrawerOpen, setIsDrawerOpen] = useState<boolean>(false);

  const [errorMessage, setErrorMessage] = useState<string>("");

  const [successMessage, setSuccessMessage] = useState<string>("");

  const [savingProgram, setSavingProgram] = useState<boolean>(false);

  const [showNotes, setShowNotes] = useState<boolean>(false);

  const [showLogs, setShowLogs] = useState<boolean>(false);

  const [showInsights, setShowInsights] = useState<boolean>(false);

  const [showProgramDetails, setShowProgramDetails] = useState<boolean>(false);

  const [simplifiedView, setSimplifiedView] = useState<boolean>(true);

  const [duplicateView, setDuplicateView] = useState<boolean>(true);

  const [progressionView, setProgressionView] = useState<boolean>(true);

  const matches = useMediaQuery("(min-width:900px)");

  const dispatch = useTypedDispatch();

  const isOpenSidebar = useTypedSelector(
    (state) => state.sidebarState.isOpenSidebar
  );

  const {
    data: hasStripeIntegration,
    isLoading: isLoadingCheckStripeIntegration,
  } = useCheckStripeIntegrationQuery();

  const [versionNumber, setVersionNumber] = useState<number>(1);

  const [selectedWorkoutProgram, setSelectedWorkoutProgram] =
    useState<ModernProgramBuilderResponse>();

  const showSidebar = matches && isOpenSidebar;

  const [updateDraftWorkoutProgram] = useUpdateDraftWorkoutProgramMutation();

  const [createNewVersionOfExistingWorkoutProgram] =
    useCreateNewVersionOfExistingWorkoutProgramMutation();

  const [completeWorkoutProgramAndShare] =
    useCompleteWorkoutProgramAndShareMutation();

  const {
    data: workoutPrograms,
    isLoading: isLoadingPrograms,
    refetch: refetchPrograms,
  } = useGetModernWorkoutProgramsQuery(workoutProgramId);

  const { data: allExercisesData, isLoading: isLoadingAllExerciseItems } =
    useGetExercisesLibraryQuery({}, {});

  const {
    data: workoutProgramGroupItems,
    isLoading: isLoadingProgramGroup,
    refetch: refetchVersions,
  } = useGetWorkoutProgramGroupItemsQuery({
    parentWorkoutProgramId: workoutProgramId,
  } as WorkoutProgramGroupItemParams);

  const findMatchingWorkoutProgram = (
    groupItems: WorkoutProgramGroupItem[],
    programs: ModernProgramBuilderResponse[],
    versionNumber: number
  ) => {
    const matchingGroupItem = groupItems?.find(
      (item) => item.versionNumber === versionNumber
    );

    if (matchingGroupItem) {
      const matchingWorkoutProgram = programs?.find(
        (item) => item.id === matchingGroupItem.childWorkoutProgramId
      );

      return matchingWorkoutProgram;
    }

    return null;
  };

  const handleCompleteWorkoutProgramAndShare = async () => {
    if (!selectedWorkoutProgram || !selectedWorkoutProgram.id) return;

    setSavingProgram(true);
    setSuccessMessage("");
    setErrorMessage("");
    setOpenCompleteAndShareModal(false);

    try {
      const saveDraftResponse = await updateDraftWorkoutProgram(
        selectedWorkoutProgram
      ).unwrap();

      const response = await completeWorkoutProgramAndShare({
        id: selectedWorkoutProgram.id,
        invoiceRequested: invoiceRequested && hasStripeIntegration,
        invoiceDueDate,
      } as CompleteAndShareModernProgramBuilderRequest).unwrap();

      setSuccessMessage("Program completed and shared successfully");
    } catch (error) {
      setErrorMessage(
        "Error completing program. Please try again. If the error persists, please save your work and contact support."
      );
    } finally {
      setInvoiceRequested(false);
      setSavingProgram(false);
      setTimeout(() => {
        setSuccessMessage("");
        setErrorMessage("");
      }, 2500);
    }
  };

  const handleUpdateDraftWorkoutProgram = async () => {
    if (!selectedWorkoutProgram) return;

    setSavingProgram(true);
    setSuccessMessage("");
    setErrorMessage("");

    try {
      const response = await updateDraftWorkoutProgram(
        selectedWorkoutProgram
      ).unwrap();
      setSuccessMessage(
        selectedWorkoutProgram.workoutProgramType === "Template"
          ? "Template saved successfully"
          : "Program saved successfully"
      );
    } catch (error) {
      setErrorMessage("Error saving program. Please try again.");
    } finally {
      setSavingProgram(false);
      setTimeout(() => {
        setSuccessMessage("");
        setErrorMessage("");
      }, 2500);
    }
  };

  const handleCreateNewVersion = async () => {
    if (!selectedWorkoutProgram) return;
    setOpenSaveVersionModal(false);
    setSavingProgram(true);
    setSuccessMessage("");
    setErrorMessage("");

    try {
      await createNewVersionOfExistingWorkoutProgram(selectedWorkoutProgram);
      await refetchPrograms();
      await refetchVersions();

      setVersionNumber(versionNumber + 1);

      setSuccessMessage("New version created successfully");
    } catch (error) {
      setErrorMessage("Error creating new version. Please try again.");
    } finally {
      setSavingProgram(false);
      setTimeout(() => {
        setSuccessMessage("");
        setErrorMessage("");
      }, 2500);
    }
  };

  useEffect(() => {
    const workoutProgramGroupVersionsLoaded =
      workoutProgramGroupItems && workoutProgramGroupItems?.length !== 0;
    if (workoutProgramGroupVersionsLoaded) {
      setVersionNumber(workoutProgramGroupItems.length);
    }
  }, [workoutProgramGroupItems]);

  useEffect(() => {
    if (workoutPrograms) {
      if (versionNumber === 1) {
        setSelectedWorkoutProgram(workoutPrograms[0]);
      } else {
        const matchingWorkoutProgram = findMatchingWorkoutProgram(
          workoutProgramGroupItems!,
          workoutPrograms!,
          versionNumber
        );

        if (matchingWorkoutProgram) {
          setSelectedWorkoutProgram(matchingWorkoutProgram);
        }
      }
    }
  }, [versionNumber, workoutPrograms, workoutProgramGroupItems]);

  useEffect(() => {
    refetchPrograms();
    refetchVersions();
    // eslint-disable-next-line
  }, []);

  const handleTitleChange = (newValue: string) => {
    setSelectedWorkoutProgram((prevProgram) => {
      if (!prevProgram || prevProgram.title === newValue) return prevProgram;

      const updatedProgram = { ...prevProgram };
      updatedProgram.title = newValue;

      dispatch(setIsModified(true));
      return updatedProgram;
    });
  };

  const handleProgramNotesChange = (newValue: string) => {
    setSelectedWorkoutProgram((prevProgram) => {
      if (!prevProgram || prevProgram.notes === newValue) return prevProgram;

      const updatedProgram = { ...prevProgram };
      updatedProgram.notes = newValue;

      dispatch(setIsModified(true));
      return updatedProgram;
    });
  };

  const parseProgression = (
    progressionStr: string
  ): ParsedProgression | null => {
    progressionStr = progressionStr.trim().toUpperCase();

    // Patterns for RPE and RIR
    const rpeRirPattern =
      /^(RPE|RIR)\s+([\d\.]+(?:-\d+(\.\d+)?)?)\s+E\/(\d+)W$/;
    const rpeRirSimplePattern =
      /^(RPE|RIR)\s+([\d\.]+(?:-\d+(\.\d+)?)?)\s+E\/W$/;

    // Pattern for 1RM
    const oneRmPattern = /^1RM\s+([\d\.]+)(KG|LBS)\s+([\d\.]+)%\s+E\/(\d+)W$/;
    const oneRmSimplePattern = /^1RM\s+([\d\.]+)(KG|LBS)\s+([\d\.]+)%\s+E\/W$/;

    let match =
      progressionStr.match(rpeRirPattern) ||
      progressionStr.match(rpeRirSimplePattern);
    if (match) {
      const type = match[1] as "RPE" | "RIR";
      const value = parseFloat(match[2]);
      const frequency = match[4] ? parseInt(match[4], 10) : 1;
      return { type, value, frequency };
    }

    match =
      progressionStr.match(oneRmPattern) ||
      progressionStr.match(oneRmSimplePattern);
    if (match) {
      const type = "1RM";
      const oneRmValue = parseFloat(match[1]);
      const unit = match[2];
      const value = parseFloat(match[3]); // Percentage increment
      const frequency = match[4] ? parseInt(match[4], 10) : 1;
      return { type, value, frequency, oneRmValue, unit };
    }

    // If no patterns matched, return null
    return null;
  };

  const applyRpeProgression = (
    baseIntensity: string,
    progressionValue: number | [number, number],
    weekIndex: number,
    type: "RPE",
    frequency: number
  ): string => {
    // Extract numeric value(s) from baseIntensity
    const intensityPattern = /([\d\.]+)(?:-([\d\.]+))?\s*(RPE|RIR)?/i;
    const match = baseIntensity.match(intensityPattern);

    if (!match) return baseIntensity;

    const baseValue = parseFloat(match[1]);
    const baseValueMax = match[2] ? parseFloat(match[2]) : null;
    const unit = match[3] ? match[3].toUpperCase() : type;

    // Calculate how many times the progression should be applied
    const timesToApply = Math.floor(weekIndex / frequency);

    let newValue: number;
    let newValueMax: number | null = null;

    if (typeof progressionValue === "number") {
      const totalIncrement = progressionValue * timesToApply;

      if (type === "RPE") {
        newValue = baseValue + totalIncrement;
        if (baseValueMax !== null) {
          newValueMax = baseValueMax + totalIncrement;
        }
      } else {
        newValue = baseValue - totalIncrement;
        if (baseValueMax !== null) {
          newValueMax = baseValueMax - totalIncrement;
        }
      }
    } else {
      // progressionValue is a range [minIncrement, maxIncrement]
      const [minIncrementPerFreq, maxIncrementPerFreq] = progressionValue;
      const minTotalIncrement = minIncrementPerFreq * timesToApply;
      const maxTotalIncrement = maxIncrementPerFreq * timesToApply;

      if (type === "RPE") {
        newValue = baseValue + minTotalIncrement;
        newValueMax =
          (baseValueMax !== null ? baseValueMax : baseValue) +
          maxTotalIncrement;
      } else {
        newValue = baseValue - minTotalIncrement;
        newValueMax =
          (baseValueMax !== null ? baseValueMax : baseValue) -
          maxTotalIncrement;
      }
    }

    // Enforce min/max limits
    if (type === "RPE") {
      if (newValue > 10) newValue = 10;
      if (newValueMax !== null && newValueMax > 10) newValueMax = 10;
    } else {
      if (newValue < 0) newValue = 0;
      if (newValueMax !== null && newValueMax < 0) newValueMax = 0;
    }

    // Build the new intensity string
    if (newValueMax !== null && newValue !== newValueMax) {
      return `${newValue.toFixed(1)}-${newValueMax.toFixed(1)} ${unit}`;
    } else {
      return `${newValue.toFixed(1)} ${unit}`;
    }
  };

  const applyOneRmProgression = (
    baseIntensity: string,
    progression: ParsedProgression,
    weekIndex: number
  ): { load: string; intensity: string } => {
    if (!progression.oneRmValue || !progression.unit)
      return { load: "", intensity: baseIntensity };

    // Extract percentage from baseIntensity (Week 1)
    const intensityPattern = /([\d\.]+)%/;
    const match = baseIntensity.match(intensityPattern);

    if (!match) return { load: "", intensity: baseIntensity };

    const basePercentage = parseFloat(match[1]);

    // Calculate the total increase in percentage for the given week (not cumulative)
    const totalIncrementPercentage = progression.value * weekIndex; // Multiply by the week index (0, 1, 2, etc.)

    // Calculate the new percentage for intensity
    const newPercentage = basePercentage + totalIncrementPercentage;

    // Calculate the new load based on the updated percentage of the 1RM
    const newLoad = progression.oneRmValue * (newPercentage / 100);

    return {
      load: `${newLoad.toFixed(1)} ${progression.unit}`, // New load in kg/lbs
      intensity: `${newPercentage.toFixed(1)}%`, // New percentage intensity
    };
  };

  const defaultExerciseObject = {
    exerciseId: 0,
    substitueExerciseId: null,
    substitueExerciseName: "",
    order: 0,
    supersetGroup: "",
    exerciseName: "",
    type: "",
    workoutItemWeightUnit: "",
    sets: "",
    reps: "",
    progression: "",
    intensity: "",
    load: "",
    rest: "",
    notes: "",
    readOnlyCompletedLog: "",
  };

  const handleInputChangeValue = useCallback(
    (
      newValue: any,
      weekIndex: number,
      dayIndex: number,
      exerciseIndex: number,
      field: string
    ) => {
      setSelectedWorkoutProgram((prevProgram) =>
        produce(prevProgram, (draft) => {
          if (!prevProgram) return;
          if (!draft) return;

          // Ensure weekIndex, dayIndex, and exerciseIndex are within bounds
          if (
            weekIndex < 0 ||
            weekIndex >= draft.weeks.length ||
            dayIndex < 0 ||
            dayIndex >= draft.weeks[weekIndex].days.length ||
            exerciseIndex < 0 ||
            exerciseIndex >=
              draft.weeks[weekIndex].days[dayIndex].exercises.length
          ) {
            console.error("Invalid indices provided to handleInputChangeValue");
            return;
          }

          // Update the specific field in the targeted week/day/exercise
          const targetExercise =
            draft.weeks[weekIndex].days[dayIndex].exercises[exerciseIndex];

          if (targetExercise) {
            (targetExercise as any)[field] = newValue;
          } else {
            // Initialize with default values and set the specific field
            draft.weeks[weekIndex].days[dayIndex].exercises[exerciseIndex] = {
              ...defaultExerciseObject,
              [field]: newValue,
            };
          }

          // Apply progression if necessary
          if (
            weekIndex === 0 &&
            (field === "progression" || field === "intensity")
          ) {
            const progressionStr =
              field === "progression" ? newValue : targetExercise.progression;
            const intensityStr =
              field === "intensity" ? newValue : targetExercise.intensity;

            if (progressionStr && intensityStr) {
              const parsedProgression = parseProgression(progressionStr);

              if (parsedProgression) {
                const frequency = parsedProgression.frequency || 1;

                // For '1RM', calculate and set the load and intensity for Week 0
                if (parsedProgression.type === "1RM") {
                  const exercise =
                    draft.weeks[weekIndex].days[dayIndex].exercises[
                      exerciseIndex
                    ];
                  const { load, intensity } = applyOneRmProgression(
                    intensityStr,
                    parsedProgression,
                    0 // Week 0
                  );
                  exercise.load = load;
                  exercise.intensity = intensity;
                }

                for (let w = 1; w < draft.weeks.length; w++) {
                  let exercise =
                    draft.weeks[w].days[dayIndex].exercises[exerciseIndex];

                  if (!exercise) {
                    // Initialize if it doesn't exist
                    exercise = { ...defaultExerciseObject };
                    draft.weeks[w].days[dayIndex].exercises[exerciseIndex] =
                      exercise;
                  }

                  const timesToApply = Math.floor(w / frequency);

                  if (timesToApply > 0) {
                    if (parsedProgression.type === "RPE") {
                      exercise.intensity = applyRpeProgression(
                        intensityStr,
                        parsedProgression.value,
                        w,
                        parsedProgression.type,
                        frequency
                      );
                    } else if (parsedProgression.type === "1RM") {
                      const { load, intensity } = applyOneRmProgression(
                        intensityStr,
                        parsedProgression,
                        w
                      );
                      exercise.load = load;
                      exercise.intensity = intensity;
                    }
                  } else {
                    // Copy previous week's values
                    const prevExercise =
                      draft.weeks[w - 1].days[dayIndex].exercises[
                        exerciseIndex
                      ];
                    exercise.intensity = prevExercise.intensity;
                    exercise.load = prevExercise.load;
                  }
                }
              }
            }
          }

          // Handle duplicateView logic
          if (duplicateView && weekIndex === 0 && field !== "progression") {
            draft.weeks.forEach((week, idx) => {
              if (idx === 0) return;
              const exercise = week.days[dayIndex].exercises[exerciseIndex];
              if (exercise) {
                (exercise as any)[field] = newValue;
              } else {
                // Initialize exercise if it doesn't exist
                week.days[dayIndex].exercises[exerciseIndex] = {
                  ...defaultExerciseObject,
                  [field]: newValue,
                };
              }
            });
          }

          // Mark the state as modified
          dispatch(setIsModified(true));
        })
      );
    },
    [dispatch, duplicateView]
  );

  const handleAddExercise = (weekIndex: number, dayIndex: number) => {
    setSelectedWorkoutProgram((prevProgram) => {
      if (!prevProgram) return prevProgram;

      const updatedProgram = { ...prevProgram };
      const updatedWeeks = [...updatedProgram.weeks];
      const week = { ...updatedWeeks[weekIndex] };
      const updatedDays = [...week.days];
      const day = { ...updatedDays[dayIndex] };
      const updatedExercises = [...day.exercises];

      // Determine the next order number
      const nextOrder =
        updatedExercises.length > 0
          ? Math.max(...updatedExercises.map((ex) => ex.order || 0)) + 1
          : 1;

      // Create a new exercise object with the correct order
      const newExercise = {
        exerciseId: 0,
        substitueExerciseId: null,
        substitueExerciseName: "",
        order: nextOrder, // Set the correct order
        supersetGroup: "",
        exerciseName: "",
        type: "",
        workoutItemWeightUnit: "",
        sets: "",
        reps: "",
        progression: "",
        intensity: "",
        load: "",
        rest: "",
        notes: "",
        readOnlyCompletedLog: "",
      };

      updatedExercises.push(newExercise);

      day.exercises = updatedExercises;
      updatedDays[dayIndex] = day;
      week.days = updatedDays;
      updatedWeeks[weekIndex] = week;
      updatedProgram.weeks = updatedWeeks;

      // If duplicateView is enabled and adding to Week 1, add to other weeks as well
      if (duplicateView && weekIndex === 0) {
        updatedWeeks.forEach((wk, idx) => {
          if (idx === 0) return; // Skip Week 1
          const duplicatedWeek = { ...wk };
          const duplicatedDays = [...duplicatedWeek.days];
          if (duplicatedDays[dayIndex]) {
            const duplicatedDay = { ...duplicatedDays[dayIndex] };
            const duplicatedExercises = [...duplicatedDay.exercises];

            // Determine the next order for duplicated exercises
            const duplicatedNextOrder =
              duplicatedExercises.length > 0
                ? Math.max(...duplicatedExercises.map((ex) => ex.order || 0)) +
                  1
                : 1;

            // Add the same exercise with updated order
            duplicatedExercises.push({
              ...newExercise,
              order: duplicatedNextOrder,
            });
            duplicatedDay.exercises = duplicatedExercises;
            duplicatedDays[dayIndex] = duplicatedDay;
            duplicatedWeek.days = duplicatedDays;
            updatedWeeks[idx] = duplicatedWeek;
          }
        });
        updatedProgram.weeks = updatedWeeks;
      }

      return updatedProgram;
    });

    dispatch(setIsModified(true));
  };

  const handleDeleteExercise = (
    weekIndex: number,
    dayIndex: number,
    exerciseIndex: number
  ) => {
    setSelectedWorkoutProgram((prevProgram) =>
      produce(prevProgram, (draft) => {
        if (!prevProgram) return;
        if (!draft) return;

        // Validate indices to prevent runtime errors
        if (
          weekIndex < 0 ||
          weekIndex >= draft.weeks.length ||
          dayIndex < 0 ||
          dayIndex >= draft.weeks[weekIndex].days.length ||
          exerciseIndex < 0 ||
          exerciseIndex >=
            draft.weeks[weekIndex].days[dayIndex].exercises.length
        ) {
          console.error("Invalid indices provided to handleDeleteExercise");
          return;
        }

        // Navigate to the specific exercise
        const week = draft.weeks[weekIndex];
        const day = week.days[dayIndex];
        const updatedExercises = day.exercises;

        // Remove the exercise at the specified index
        updatedExercises.splice(exerciseIndex, 1);

        // Reassign order values to remaining exercises
        updatedExercises.forEach((exercise, idx) => {
          exercise.order = idx + 1; // Ensure order starts at 1
        });

        // Handle duplicateView if necessary
        if (duplicateView && weekIndex === 0) {
          draft.weeks.forEach((wk, idx) => {
            if (idx === 0) return; // Skip Week 1

            const duplicatedWeek = wk;
            const duplicatedDay = duplicatedWeek.days[dayIndex];
            if (
              duplicatedDay &&
              duplicatedDay.exercises.length > exerciseIndex
            ) {
              duplicatedDay.exercises.splice(exerciseIndex, 1);
              duplicatedDay.exercises.forEach((exercise, idx) => {
                exercise.order = idx + 1;
              });
            }
          });
        }

        // Mark the state as modified
        dispatch(setIsModified(true));
      })
    );
  };

  const fieldNames = [
    "order",
    "exerciseName",
    "sets",
    "reps",
    "progression",
    "intensity",
    "load",
    "supersetGroup",
    "rest",
    "notes",
    "readOnlyCompletedLog",
  ];

  const inputRefs = useRef<{ [key: string]: HTMLInputElement | null }>({});

  // Prepare max indices for navigation
  const maxIndices = useMemo(() => {
    const weeks = selectedWorkoutProgram?.weeks.length || 0;
    const daysPerWeek = selectedWorkoutProgram?.weeks.map(
      (week) => week.days.length
    ) || [0];
    const exercisesPerDay = selectedWorkoutProgram?.weeks.map((week) =>
      week.days.map((day) => day.exercises.length)
    ) || [[]];

    return {
      weeks,
      daysPerWeek,
      exercisesPerDay,
    };
  }, [selectedWorkoutProgram]);

  const StyledTableContainer = styled("div")({
    display: "flex",
    flexDirection: "column",
    width: "100%",
    marginTop: "10px",
  });

  const StyledHeaderRow = styled("div")({
    display: "flex",
    backgroundColor: "#e0e0e0",
    borderBottom: "2px solid #ccc",
  });

  const StyledHeaderCell = styled("div")<{
    width: number;
    isSelected?: boolean;
  }>(({ width, isSelected }) => ({
    width: `${width}px`,
    textAlign: "left",
    padding: "12px 8px",
    fontWeight: 600,
    color: "#333",
    borderRight: "1px solid #ccc",
    boxSizing: "border-box",
    height: "50px",
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
    backgroundColor: isSelected ? "#e3f2fd" : "#e0e0e0",
  }));

  const StyledRow = styled("div")<{ isEven: boolean }>(({ isEven }) => ({
    display: "flex",
    borderBottom: "1px solid #ccc",
    backgroundColor: isEven ? "#ffffff" : "#f7fcff", // Alternating colors
    "&:hover": {
      backgroundColor: "#f5f5f5",
    },
  }));

  const StyledCell = styled("div")<{
    width: number;
    isSelected?: boolean;
  }>(({ width, isSelected }) => ({
    width: `${width}px`,
    padding: "8px",
    color: "#212529",
    fontSize: "14px",
    borderRight: "1px solid #ccc",
    borderTop: isSelected ? "2px solid #1976d2" : "none",
    backgroundColor: isSelected ? "#d9edfc" : "transparent",
    boxSizing: "border-box",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    height: "50px",
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
  }));

  const inputStyle: React.CSSProperties = {
    textAlign: "left",
  };

  const handleSelectedWorkoutProgramGroupItemChange = (
    event: React.SyntheticEvent,
    newValue: string
  ) => {
    setVersionNumber(Number(newValue));
    const matchingWorkoutProgram = findMatchingWorkoutProgram(
      workoutProgramGroupItems!!,
      workoutPrograms!!,
      Number(newValue)
    );

    if (matchingWorkoutProgram) {
      setSelectedWorkoutProgram(matchingWorkoutProgram);
    }
  };

  // States for Selected Cell and Editing Cell
  const [selectedCells, setSelectedCells] = useState<
    Array<{
      weekIndex: number;
      dayIndex: number;
      exerciseIndex: number;
      fieldName: string;
    }>
  >([]);

  const [currentCell, setCurrentCell] = useState<{
    weekIndex: number;
    dayIndex: number;
    exerciseIndex: number;
    fieldName: string;
  } | null>(null);

  const [editingCell, setEditingCell] = useState<{
    weekIndex: number;
    dayIndex: number;
    exerciseIndex: number;
    fieldName: string;
  } | null>(null);

  const [copiedValue, setCopiedValue] = useState<string>("");

  const isUltraWide = useMediaQuery("(min-width:2560px)");
  const isExtraLarge = useMediaQuery(
    "(min-width:1920px) and (max-width:2559px)"
  );
  const isDesktop = useMediaQuery("(min-width:1600px) and (max-width:1919px)");
  const isLaptop = useMediaQuery("(min-width:1440px)");
  const isLaptopSmall = useMediaQuery(
    "(min-width:1239px) and (max-width:1439px)"
  );
  const isTablet = useMediaQuery("(min-width:904px) and (max-width:1238px)");
  const isTabletSmall = useMediaQuery(
    "(min-width:768px) and (max-width:903px)"
  );
  const isMobile = useMediaQuery("(max-width:767px)");

  const SIDEBAR_WIDTH = 220;
  const weekWidth = useMemo(() => {
    if (isUltraWide) return 2560;
    if (isExtraLarge) return 1920;
    if (isDesktop) return 1600;
    if (isLaptop) return 1440;
    if (isLaptopSmall) return 1239;
    if (isTablet) return 904;
    if (isTabletSmall) return 768;
    if (isMobile) return 600;
    return 1440;
  }, [isLaptop, isLaptopSmall, isTablet, isTabletSmall, isMobile]);

  // Ref for the grid container to handle key events
  const gridRef = useRef<HTMLDivElement>(null);

  const filteredWeeks = useMemo(() => {
    return (
      selectedWorkoutProgram?.weeks?.filter(
        (week, idx) => !simplifiedView || idx === 0
      ) || []
    );
  }, [selectedWorkoutProgram, simplifiedView]);

  const virtualizer = useVirtualizer({
    horizontal: true,
    count: filteredWeeks.length,
    getScrollElement: () => gridRef.current,
    estimateSize: () => weekWidth,
    overscan: 0,
  });

  const handleBlurEditing = () => {
    setEditingCell(null); // Exit edit mode
    if (gridRef.current) {
      gridRef.current.focus(); // Refocus the grid container
    }
  };

  const isCellSelected = (cell: {
    weekIndex: number;
    dayIndex: number;
    exerciseIndex: number;
    fieldName: string;
  }) => {
    return selectedCells.some(
      (selectedCell) =>
        selectedCell.weekIndex === cell.weekIndex &&
        selectedCell.dayIndex === cell.dayIndex &&
        selectedCell.exerciseIndex === cell.exerciseIndex &&
        selectedCell.fieldName === cell.fieldName
    );
  };

  const isRowSelected = (
    weekIndex: number,
    dayIndex: number,
    exerciseIndex: number
  ) => {
    return selectedCells.some(
      (cell) =>
        cell.weekIndex === weekIndex &&
        cell.dayIndex === dayIndex &&
        cell.exerciseIndex === exerciseIndex
    );
  };

  const isColumnSelected = (
    fieldName: string,
    weekIndex: number,
    dayIndex: number
  ) => {
    const exercises =
      selectedWorkoutProgram?.weeks[weekIndex].days[dayIndex].exercises || [];
    if (exercises.length === 0) return false;
    return exercises.every((_, exerciseIndex) =>
      selectedCells.some(
        (cell) =>
          cell.weekIndex === weekIndex &&
          cell.dayIndex === dayIndex &&
          cell.exerciseIndex === exerciseIndex &&
          cell.fieldName === fieldName
      )
    );
  };

  // Handle Cell Click to set selection
  const handleCellClick = (
    weekIndex: number,
    dayIndex: number,
    exerciseIndex: number,
    fieldName: string
  ) => {
    setSelectedCells([{ weekIndex, dayIndex, exerciseIndex, fieldName }]);
    setCurrentCell({ weekIndex, dayIndex, exerciseIndex, fieldName });
    // If a cell is being edited, clicking another cell should stop editing
    if (editingCell) {
      setEditingCell(null);
    }
  };

  // Handle Row Header Click to select entire row
  const handleRowHeaderClick = (
    weekIndex: number,
    dayIndex: number,
    exerciseIndex: number
  ) => {
    const cells: any[] = [];
    fieldNames.forEach((fieldName) => {
      if (fieldName === "notes" && !showNotes) return;
      if (fieldName === "readOnlyCompletedLog" && !showLogs) return;
      if (fieldName === "progression" && !progressionView) return;
      cells.push({ weekIndex, dayIndex, exerciseIndex, fieldName });
    });
    setSelectedCells(cells);
    setCurrentCell(null);
  };

  // Handle Header Cell Click to select entire column
  const handleHeaderCellClick = (
    fieldName: string,
    weekIndex: number,
    dayIndex: number
  ) => {
    const cells: any[] = [];
    const exercises =
      selectedWorkoutProgram?.weeks[weekIndex].days[dayIndex].exercises || [];
    exercises.forEach((_, exerciseIndex) => {
      cells.push({ weekIndex, dayIndex, exerciseIndex, fieldName });
    });
    setSelectedCells(cells);
    setCurrentCell(null);
  };

  // Handle Key Down Events for Navigation and Copy-Paste
  const handleKeyDown = useCallback(
    (e: KeyboardEvent<HTMLDivElement>) => {
      // Ignore key events if an input or select is focused
      if (
        e.target instanceof HTMLInputElement ||
        e.target instanceof HTMLSelectElement
      ) {
        return;
      }

      // Handle Copy (Ctrl+C or Cmd+C)
      if ((e.ctrlKey || e.metaKey) && (e.key === "c" || e.key === "C")) {
        e.preventDefault();
        if (selectedCells.length === 0) return;

        // Build the copied data
        const sortedCells = [...selectedCells].sort((a, b) => {
          // Sort by week, day, exercise, field
          if (a.weekIndex !== b.weekIndex) return a.weekIndex - b.weekIndex;
          if (a.dayIndex !== b.dayIndex) return a.dayIndex - b.dayIndex;
          if (a.exerciseIndex !== b.exerciseIndex)
            return a.exerciseIndex - b.exerciseIndex;
          return (
            fieldNames.indexOf(a.fieldName) - fieldNames.indexOf(b.fieldName)
          );
        });

        // Organize data into rows and columns
        const dataGrid: string[][] = [];
        let currentRowKey = "";
        let currentRow: string[] = [];

        sortedCells.forEach((cell) => {
          const rowKey = `${cell.weekIndex}-${cell.dayIndex}-${cell.exerciseIndex}`;
          if (rowKey !== currentRowKey) {
            if (currentRow.length > 0) {
              dataGrid.push(currentRow);
            }
            currentRow = [];
            currentRowKey = rowKey;
          }
          const exercise =
            selectedWorkoutProgram?.weeks[cell.weekIndex].days[cell.dayIndex]
              .exercises[cell.exerciseIndex];
          const value = String(
            exercise?.[cell.fieldName as keyof typeof exercise] ?? ""
          );
          currentRow.push(value);
        });

        if (currentRow.length > 0) {
          dataGrid.push(currentRow);
        }

        // Convert data grid to string
        const valueToCopy = dataGrid.map((row) => row.join("\t")).join("\n");
        setCopiedValue(valueToCopy);

        // Copy to system clipboard
        navigator.clipboard.writeText(valueToCopy);
        return;
      }

      // Handle Paste (Ctrl+V or Cmd+V)
      if ((e.ctrlKey || e.metaKey) && (e.key === "v" || e.key === "V")) {
        e.preventDefault();
        if (selectedCells.length === 0) return;

        navigator.clipboard.readText().then((clipText) => {
          if (!clipText) return;

          // Parse the clipboard data
          const rows = clipText.split("\n").map((row) => row.split("\t"));

          // Apply the values to the selected cells
          const sortedCells = [...selectedCells].sort((a, b) => {
            if (a.weekIndex !== b.weekIndex) return a.weekIndex - b.weekIndex;
            if (a.dayIndex !== b.dayIndex) return a.dayIndex - b.dayIndex;
            if (a.exerciseIndex !== b.exerciseIndex)
              return a.exerciseIndex - b.exerciseIndex;
            return (
              fieldNames.indexOf(a.fieldName) - fieldNames.indexOf(b.fieldName)
            );
          });

          let cellIndex = 0;
          for (let i = 0; i < rows.length; i++) {
            const row = rows[i];
            for (let j = 0; j < row.length; j++) {
              if (cellIndex >= sortedCells.length) break;
              const cell = sortedCells[cellIndex];
              const value = row[j];
              handleInputChangeValue(
                value,
                cell.weekIndex,
                cell.dayIndex,
                cell.exerciseIndex,
                cell.fieldName
              );
              cellIndex++;
            }
          }
        });
        return;
      }

      // Handle navigation
      if (!currentCell) return;
      const { weekIndex, dayIndex, exerciseIndex, fieldName } = currentCell;

      // Find the current field index
      const currentFieldIndex = fieldNames.indexOf(fieldName);
      if (currentFieldIndex === -1) return;

      let newWeekIndex = weekIndex;
      let newDayIndex = dayIndex;
      let newExerciseIndex = exerciseIndex;
      let newFieldIndex = currentFieldIndex;

      switch (e.key) {
        case "ArrowRight":
          e.preventDefault();
          if (currentFieldIndex < fieldNames.length - 1) {
            newFieldIndex += 1;
          } else {
            // Move to next exercise
            if (
              exerciseIndex <
              (maxIndices.exercisesPerDay[weekIndex][dayIndex] || 0) - 1
            ) {
              newExerciseIndex += 1;
              newFieldIndex = 0;
            } else {
              // Move to next day
              if (dayIndex < (maxIndices.daysPerWeek[weekIndex] || 0) - 1) {
                newDayIndex += 1;
                newExerciseIndex = 0;
                newFieldIndex = 0;
              } else {
                // Move to next week
                if (weekIndex < (maxIndices.weeks || 0) - 1) {
                  newWeekIndex += 1;
                  newDayIndex = 0;
                  newExerciseIndex = 0;
                  newFieldIndex = 0;
                }
              }
            }
          }
          break;
        case "ArrowLeft":
          e.preventDefault();
          if (currentFieldIndex > 0) {
            newFieldIndex -= 1;
          } else {
            // Move to previous exercise
            if (exerciseIndex > 0) {
              newExerciseIndex -= 1;
              newFieldIndex = fieldNames.length - 1;
            } else {
              // Move to previous day
              if (dayIndex > 0) {
                newDayIndex -= 1;
                newExerciseIndex =
                  (maxIndices.exercisesPerDay[weekIndex][dayIndex - 1] || 0) -
                  1;
                newFieldIndex = fieldNames.length - 1;
              } else {
                // Move to previous week
                if (weekIndex > 0) {
                  newWeekIndex -= 1;
                  newDayIndex =
                    (maxIndices.daysPerWeek[weekIndex - 1] || 0) - 1;
                  newExerciseIndex =
                    (maxIndices.exercisesPerDay[weekIndex - 1][newDayIndex] ||
                      0) - 1;
                  newFieldIndex = fieldNames.length - 1;
                }
              }
            }
          }
          break;
        case "ArrowDown":
          e.preventDefault();
          if (
            exerciseIndex <
            (maxIndices.exercisesPerDay[weekIndex][dayIndex] || 0) - 1
          ) {
            newExerciseIndex += 1;
          }
          break;
        case "ArrowUp":
          e.preventDefault();
          if (exerciseIndex > 0) {
            newExerciseIndex -= 1;
          }
          break;
        case "Tab":
          e.preventDefault();
          if (e.shiftKey) {
            // Handle Shift+Tab (similar to ArrowLeft)
            if (currentFieldIndex > 0) {
              newFieldIndex -= 1;
            } else {
              if (exerciseIndex > 0) {
                newExerciseIndex -= 1;
                newFieldIndex = fieldNames.length - 1;
              }
            }
          } else {
            // Handle Tab (similar to ArrowRight)
            if (currentFieldIndex < fieldNames.length - 1) {
              newFieldIndex += 1;
            } else {
              if (
                exerciseIndex <
                (maxIndices.exercisesPerDay[weekIndex][dayIndex] || 0) - 1
              ) {
                newExerciseIndex += 1;
                newFieldIndex = 0;
              }
            }
          }
          break;
        case "Enter":
          e.preventDefault();
          if (editingCell) {
            // If already editing, stop editing
            setEditingCell(null);
          } else {
            // Start editing
            setEditingCell({ weekIndex, dayIndex, exerciseIndex, fieldName });
          }
          return;
        default:
          break;
      }

      // Validate new indices
      if (
        newWeekIndex >= 0 &&
        newWeekIndex < maxIndices.weeks &&
        newDayIndex >= 0 &&
        newDayIndex < (maxIndices.daysPerWeek[newWeekIndex] || 0) &&
        newExerciseIndex >= 0 &&
        newExerciseIndex <
          (maxIndices.exercisesPerDay[newWeekIndex][newDayIndex] || 0) &&
        newFieldIndex >= 0 &&
        newFieldIndex < fieldNames.length
      ) {
        const newFieldName = fieldNames[newFieldIndex];
        setSelectedCells([
          {
            weekIndex: newWeekIndex,
            dayIndex: newDayIndex,
            exerciseIndex: newExerciseIndex,
            fieldName: newFieldName,
          },
        ]);
        setCurrentCell({
          weekIndex: newWeekIndex,
          dayIndex: newDayIndex,
          exerciseIndex: newExerciseIndex,
          fieldName: newFieldName,
        });
      }
    },
    [
      selectedCells,
      currentCell,
      editingCell,
      fieldNames,
      maxIndices,
      handleInputChangeValue,
      selectedWorkoutProgram,
    ]
  );

  // Attach keydown listener
  useEffect(() => {
    const gridElement = gridRef.current;
    if (!gridElement) return;

    const handleKeyDownEvent = (e: KeyboardEvent<HTMLDivElement>) => {
      handleKeyDown(e);
    };

    gridElement.addEventListener("keydown", handleKeyDownEvent as any);

    return () => {
      gridElement.removeEventListener("keydown", handleKeyDownEvent as any);
    };
  }, [handleKeyDown]);

  // Focus the selected input when editingCell changes
  useEffect(() => {
    if (!editingCell) return;
    const { weekIndex, dayIndex, exerciseIndex, fieldName } = editingCell;
    const fieldKey = getFieldKey(weekIndex, dayIndex, exerciseIndex, fieldName);
    const input = inputRefs.current[fieldKey];
    if (input) {
      input.focus();
      if (typeof input.select === "function") {
        input.select();
      }
    }
  }, [editingCell]);

  return (
    <Box
      sx={
        showSidebar
          ? sharedStyles.containers.sidebar.content
          : sharedStyles.containers.sidebar.contentFullscreen
      }
    >
      <Box sx={sharedStyles.containers.sidebar.heading}>
        <Typography sx={sharedStyles.headings.sidebar}>
          {selectedWorkoutProgram &&
          selectedWorkoutProgram.workoutProgramType === "Template"
            ? "Template builder"
            : "Program builder"}
        </Typography>
      </Box>
      {selectedWorkoutProgram && (
        <>
          <UserInfoCard elevation={3}>
            {selectedWorkoutProgram.userId && (
              <IdenticonAvatar
                sizeValue={60}
                seedValue={selectedWorkoutProgram.userId.toString()}
                profileImageUrl={selectedWorkoutProgram.profileImageUrl || ""}
              />
            )}
            <UserDetails spacing={0.5}>
              {selectedWorkoutProgram.userId && (
                <>
                  <Typography
                    variant="h6"
                    sx={{
                      fontFamily: "Inter",
                      fontWeight: 600,
                      color: Colors.gray[900],
                    }}
                  >
                    {selectedWorkoutProgram.userName}
                  </Typography>
                  <Typography
                    variant="body2"
                    sx={{
                      fontFamily: "Inter",
                      color: Colors.gray[600],
                    }}
                  >
                    {selectedWorkoutProgram.userEmail}
                  </Typography>
                </>
              )}
              {/* Program Duration Info */}
              <ProgramInfo>
                <Typography variant="body2">
                  {selectedWorkoutProgram.daysPerWeek}{" "}
                  {selectedWorkoutProgram.daysPerWeek === 1 ? "day" : "days"}{" "}
                  per week for {selectedWorkoutProgram.weeks.length}{" "}
                  {selectedWorkoutProgram.weeks.length === 1 ? "week" : "weeks"}
                </Typography>
              </ProgramInfo>
              {selectedWorkoutProgram.startDate && (
                <ProgramInfo>
                  <Typography variant="body2">
                    {dayjs(selectedWorkoutProgram.startDate).format(
                      "MMM D, YYYY"
                    )}{" "}
                    -{" "}
                    {dayjs(selectedWorkoutProgram.endDate).format(
                      "MMM D, YYYY"
                    )}
                  </Typography>
                </ProgramInfo>
              )}
            </UserDetails>
          </UserInfoCard>
          <Box
            sx={{
              display: "flex",
              flexGrow: 1,
              ml: 2,
              mb: 2,
              mt: 2,
            }}
          >
            <FormControlLabel
              control={
                <Switch
                  size="small"
                  onChange={() => setShowProgramDetails(!showProgramDetails)}
                  checked={showProgramDetails}
                />
              }
              label={
                selectedWorkoutProgram.workoutProgramType === "Template"
                  ? "Template details"
                  : "Program details"
              }
              sx={{
                mt: 1,
                "& span": {
                  fontFamily: "Inter",
                  fontStyle: "normal",
                  fontWeight: "400",
                  fontSize: "14px",
                  lineHeight: "20px",
                  color: `${Colors.oxford[1100]}`,
                },
              }}
            />
          </Box>
          {showProgramDetails && (
            <Stack spacing={3} sx={{ mb: 2 }}>
              <StyledTextField
                value={selectedWorkoutProgram.title}
                onChange={(e) => handleTitleChange(e.target.value)}
                variant="outlined"
                label="Title"
                fullWidth
                InputLabelProps={{
                  style: { fontWeight: 500 },
                }}
                sx={{
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      borderColor: Colors.gray[300],
                    },
                    "&:hover fieldset": {
                      borderColor: Colors.blue[500],
                    },
                    "&.Mui-focused fieldset": {
                      borderColor: Colors.blue[700],
                    },
                  },
                }}
              />
              <StyledTextField
                value={selectedWorkoutProgram.notes}
                onChange={(e) => handleProgramNotesChange(e.target.value)}
                variant="outlined"
                label="Notes"
                fullWidth
                multiline
                rows={5}
                InputLabelProps={{
                  style: { fontWeight: 500 },
                }}
                sx={{
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      borderColor: Colors.gray[300],
                    },
                    "&:hover fieldset": {
                      borderColor: Colors.blue[500],
                    },
                    "&.Mui-focused fieldset": {
                      borderColor: Colors.blue[700],
                    },
                  },
                }}
              />
            </Stack>
          )}
          {selectedWorkoutProgram.workoutProgramType === "UserProgram" && (
            <Box
              sx={{
                display: "flex",
                flexGrow: 1,
                ml: 2,
                mb: 2,
                mt: 2,
              }}
            >
              <FormControlLabel
                control={
                  <Switch
                    size="small"
                    onChange={() => setShowInsights(!showInsights)}
                    checked={showInsights}
                  />
                }
                label="Insights"
                sx={{
                  mt: 1,
                  "& span": {
                    fontFamily: "Inter",
                    fontStyle: "normal",
                    fontWeight: "400",
                    fontSize: "14px",
                    lineHeight: "20px",
                    color: `${Colors.oxford[1100]}`,
                  },
                }}
              />
            </Box>
          )}
          {showInsights && selectedWorkoutProgram.userId && (
            <Grid container spacing={2}>
              <ClientInsights
                selectedUserId={selectedWorkoutProgram.userId}
                showTitle
                showFatigue
                showRating
                showVolume
              />
              <Grid item xs={12} md={12}>
                <TrainingInsightsContent
                  preselectedUserId={selectedWorkoutProgram.userId}
                />
              </Grid>
            </Grid>
          )}

          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              height: "900px",
              background: "#fff",
              mb: 4,
            }}
          >
            <TabContext value={versionNumber.toString()}>
              <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <TabList
                  onChange={handleSelectedWorkoutProgramGroupItemChange}
                  aria-label="Tabs"
                >
                  {workoutProgramGroupItems?.map((workoutProgramGroupItem) => (
                    <Tab
                      key={workoutProgramGroupItem.id}
                      label={`Version ${workoutProgramGroupItem.versionNumber}`}
                      value={workoutProgramGroupItem.versionNumber.toString()}
                    />
                  ))}
                </TabList>
              </Box>
            </TabContext>

            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                flexGrow: 1,
                ml: 2,
                mb: 2,
                mt: 2,
              }}
            >
              <Button
                variant="outlined"
                color="primary"
                onClick={() => setIsDrawerOpen(!isDrawerOpen)}
                sx={{
                  textTransform: "none",
                  textDecoration: "none",
                }}
              >
                Settings
              </Button>
              {selectedWorkoutProgram &&
                selectedWorkoutProgram.workoutProgramType === "Template" && (
                  <Box sx={{ ml: "auto", display: "flex", gap: 2 }}>
                    <LoadingButton
                      variant="contained"
                      color="primary"
                      sx={{
                        fontFamily: "Inter",
                        fontStyle: "normal",
                        fontWeight: "500",
                        fontSize: "14px",
                        lineHeight: "24px",
                        letterSpacing: "0.4px",
                        textTransform: "none",
                        textDecoration: "none",
                        mr: 2,
                      }}
                      onClick={handleUpdateDraftWorkoutProgram}
                      loading={savingProgram}
                      disabled={
                        savingProgram || isLoadingCheckStripeIntegration
                      }
                    >
                      Save template
                    </LoadingButton>
                  </Box>
                )}
              {selectedWorkoutProgram &&
                selectedWorkoutProgram.workoutProgramType === "UserProgram" && (
                  <Box sx={{ ml: "auto", display: "flex", gap: 2 }}>
                    {selectedWorkoutProgram.status !== "Draft" && (
                      <LoadingButton
                        variant="contained"
                        color="primary"
                        sx={{
                          fontFamily: "Inter",
                          fontStyle: "normal",
                          fontWeight: "500",
                          fontSize: "14px",
                          lineHeight: "24px",
                          letterSpacing: "0.4px",
                          textTransform: "none",
                          textDecoration: "none",
                          mr: 2,
                        }}
                        onClick={() => setOpenSaveVersionModal(true)}
                        loading={savingProgram}
                        disabled={
                          savingProgram || isLoadingCheckStripeIntegration
                        }
                      >
                        Create new version
                      </LoadingButton>
                    )}

                    {selectedWorkoutProgram.status === "Draft" &&
                      selectedWorkoutProgram.workoutProgramType ===
                        "UserProgram" && (
                        <>
                          <LoadingButton
                            variant="outlined"
                            color="primary"
                            sx={{
                              fontFamily: "Inter",
                              fontStyle: "normal",
                              fontWeight: "500",
                              fontSize: "14px",
                              lineHeight: "24px",
                              letterSpacing: "0.4px",
                              textTransform: "none",
                              textDecoration: "none",
                            }}
                            onClick={handleUpdateDraftWorkoutProgram}
                            loading={savingProgram}
                            disabled={
                              savingProgram || isLoadingCheckStripeIntegration
                            }
                          >
                            Save as draft
                          </LoadingButton>

                          <LoadingButton
                            variant="contained"
                            color="primary"
                            sx={{
                              fontFamily: "Inter",
                              fontStyle: "normal",
                              fontWeight: "500",
                              fontSize: "14px",
                              lineHeight: "24px",
                              letterSpacing: "0.4px",
                              textTransform: "none",
                              textDecoration: "none",
                              mr: 2,
                            }}
                            onClick={() =>
                              hasStripeIntegration
                                ? setOpenCompleteAndShareModal(true)
                                : handleCompleteWorkoutProgramAndShare()
                            }
                            loading={savingProgram}
                            disabled={
                              savingProgram || isLoadingCheckStripeIntegration
                            }
                          >
                            Complete and share
                          </LoadingButton>
                        </>
                      )}
                  </Box>
                )}
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                paddingTop: 2,
                paddingRight: 2,
              }}
            >
              {successMessage && (
                <Typography
                  sx={{
                    color: "green",
                    fontSize: "14px",
                    lineHeight: "24px",
                    letterSpacing: "0.4px",
                    textTransform: "none",
                    textDecoration: "none",
                    ml: "16px",
                  }}
                >
                  {successMessage}
                </Typography>
              )}
              {errorMessage && (
                <Typography
                  sx={{
                    color: "red",
                    fontSize: "14px",
                    lineHeight: "24px",
                    letterSpacing: "0.4px",
                    textTransform: "none",
                    textDecoration: "none",
                    ml: "16px",
                  }}
                >
                  {errorMessage}
                </Typography>
              )}
            </Box>
            {/* Modify the Box that wraps the weeks */}
            <Box
              ref={gridRef}
              tabIndex={0}
              sx={{
                overflowX: "auto",
                display: "flex",
                flexWrap: "nowrap",
                gap: 4,
                padding: 2,
                width: "100%",
                height: "100%",
                flexGrow: 1,
                outline: "none",
                position: "relative",
              }}
            >
              <Box
                style={{
                  display: "flex",
                  height: "100%",
                  width: simplifiedView
                    ? "100%"
                    : `${virtualizer.getTotalSize()}px`,
                  position: "relative",
                }}
              >
                {virtualizer.getVirtualItems().map((virtualItem) => {
                  const week = filteredWeeks?.[virtualItem.index] ?? null;
                  if (!week) return null;

                  return (
                    // Modify the week Box
                    <Box
                      key={week.weekNumber}
                      ref={virtualizer.measureElement}
                      sx={{
                        position: "absolute",
                        left: 0,
                        top: 0,
                        height: "100%",
                        transform: simplifiedView
                          ? "translateX(0)"
                          : `translateX(${virtualItem.start}px)`,
                        width: simplifiedView
                          ? "100%"
                          : `${virtualItem.size}px`,
                        minWidth: simplifiedView ? "100%" : "300px",
                        flexShrink: 0,
                        flexGrow: simplifiedView ? 0 : 1,
                        padding: 2,
                        overflowY: "auto",
                        display: "flex",
                        flexDirection: "column",
                        boxSizing: "border-box",
                        marginRight: simplifiedView ? 0 : 8, // Remove margin in simplified view
                      }}
                    >
                      <Typography variant="h5">
                        {simplifiedView
                          ? "Base Week"
                          : `Week ${week.weekNumber}`}
                      </Typography>
                      {week.days.map((day, dayIndex) => (
                        <React.Fragment key={day.dayNumber}>
                          <Box sx={{ mt: 4 }}>
                            <Typography variant="h6">
                              Day {day.dayNumber}
                            </Typography>
                          </Box>

                          <StyledTableContainer>
                            <StyledHeaderRow>
                              {fieldNames.map((fieldName) => {
                                if (fieldName === "notes" && !showNotes) {
                                  return null; // Skip rendering this header
                                }
                                if (
                                  fieldName === "readOnlyCompletedLog" &&
                                  !showLogs
                                ) {
                                  return null; // Skip rendering this header
                                }
                                if (
                                  fieldName === "progression" &&
                                  !progressionView
                                ) {
                                  return null; // Skip rendering this header
                                }

                                const width = columnWidths[fieldName] || 100;
                                let headerLabel = fieldName;
                                if (fieldName === "order")
                                  headerLabel = "Order";
                                else if (fieldName === "exerciseName")
                                  headerLabel = "Exercise";
                                else if (fieldName === "sets")
                                  headerLabel = "Sets";
                                else if (fieldName === "reps")
                                  headerLabel = "Reps";
                                else if (fieldName === "load")
                                  headerLabel = "Weight";
                                else if (fieldName === "intensity")
                                  headerLabel = "Intensity";
                                else if (fieldName === "progression")
                                  headerLabel = "Progression";
                                else if (fieldName === "supersetGroup")
                                  headerLabel = "Superset";
                                else if (fieldName === "rest")
                                  headerLabel = "Rest";
                                else if (fieldName === "notes")
                                  headerLabel = "Notes";
                                else if (fieldName === "readOnlyCompletedLog")
                                  headerLabel = "Logs";

                                return (
                                  <StyledHeaderCell
                                    key={fieldName}
                                    width={width}
                                    onClick={() =>
                                      handleHeaderCellClick(
                                        fieldName,
                                        virtualItem.index,
                                        dayIndex
                                      )
                                    }
                                    isSelected={isColumnSelected(
                                      fieldName,
                                      virtualItem.index,
                                      dayIndex
                                    )}
                                  >
                                    {headerLabel}
                                  </StyledHeaderCell>
                                );
                              })}
                              <StyledHeaderCell width={40} />
                            </StyledHeaderRow>
                            {day.exercises.map((exercise, exerciseIndex) => (
                              <StyledRow
                                key={
                                  `week-${virtualItem.index}_day-${dayIndex}_exercise-${exerciseIndex}_` +
                                  exercise.exerciseId
                                }
                                isEven={exerciseIndex % 2 === 0}
                              >
                                {fieldNames.map((fieldName) => {
                                  if (fieldName === "notes" && !showNotes) {
                                    return null; // Skip rendering this cell
                                  }
                                  if (
                                    fieldName === "readOnlyCompletedLog" &&
                                    !showLogs
                                  ) {
                                    return null; // Skip rendering this cell
                                  }
                                  if (
                                    fieldName === "progression" &&
                                    !progressionView
                                  ) {
                                    return null; // Skip rendering this cell
                                  }

                                  const fieldValue =
                                    exercise[
                                      fieldName as keyof typeof exercise
                                    ];
                                  const fieldKey = getFieldKey(
                                    virtualItem.index,
                                    dayIndex,
                                    exerciseIndex,
                                    fieldName
                                  );

                                  // Determine if this cell is selected or editing
                                  const isSelectedCell = isCellSelected({
                                    weekIndex: virtualItem.index,
                                    dayIndex,
                                    exerciseIndex,
                                    fieldName,
                                  });

                                  const isEditingCell =
                                    editingCell &&
                                    editingCell.weekIndex ===
                                      virtualItem.index &&
                                    editingCell.dayIndex === dayIndex &&
                                    editingCell.exerciseIndex ===
                                      exerciseIndex &&
                                    editingCell.fieldName === fieldName;

                                  const width = columnWidths[fieldName] || 100;

                                  return (
                                    <StyledCell
                                      key={fieldName}
                                      width={width}
                                      isSelected={
                                        !!(isSelectedCell || isEditingCell)
                                      }
                                    >
                                      {fieldName === "exerciseName" ? (
                                        <Autocomplete
                                          freeSolo
                                          sx={{ width: "100%", mt: "-10px" }}
                                          options={allExercisesData ?? []}
                                          getOptionLabel={(option) =>
                                            typeof option === "string"
                                              ? option
                                              : option.name
                                          }
                                          renderOption={(props, option) => (
                                            <li {...props} key={option.id}>
                                              {option.name}
                                            </li>
                                          )}
                                          value={
                                            allExercisesData?.find(
                                              (ex) =>
                                                ex.id === exercise.exerciseId
                                            ) ||
                                            exercise.exerciseName ||
                                            ""
                                          }
                                          onChange={(_e, selectedExercise) => {
                                            if (
                                              typeof selectedExercise ===
                                              "string"
                                            ) {
                                              // User typed a custom exercise name
                                              handleInputChangeValue(
                                                selectedExercise,
                                                virtualItem.index,
                                                dayIndex,
                                                exerciseIndex,
                                                "exerciseName"
                                              );
                                              handleInputChangeValue(
                                                "0", // or null, depending on how you handle custom exercises
                                                virtualItem.index,
                                                dayIndex,
                                                exerciseIndex,
                                                "exerciseId"
                                              );
                                            } else if (selectedExercise) {
                                              // User selected an existing exercise
                                              handleInputChangeValue(
                                                selectedExercise.name,
                                                virtualItem.index,
                                                dayIndex,
                                                exerciseIndex,
                                                "exerciseName"
                                              );
                                              handleInputChangeValue(
                                                selectedExercise.id.toString(),
                                                virtualItem.index,
                                                dayIndex,
                                                exerciseIndex,
                                                "exerciseId"
                                              );
                                            } else {
                                              // Handle clearing the selection
                                              handleInputChangeValue(
                                                "",
                                                virtualItem.index,
                                                dayIndex,
                                                exerciseIndex,
                                                "exerciseName"
                                              );
                                              handleInputChangeValue(
                                                "0",
                                                virtualItem.index,
                                                dayIndex,
                                                exerciseIndex,
                                                "exerciseId"
                                              );
                                            }
                                          }}
                                          renderInput={(params) => (
                                            <TextField
                                              {...params}
                                              variant="standard"
                                              placeholder="Select or type exercise"
                                            />
                                          )}
                                          clearOnEscape
                                        />
                                      ) : (
                                        <InputCell
                                          initialValue={String(
                                            fieldValue || ""
                                          )}
                                          onValueChange={(newValue) =>
                                            handleInputChangeValue(
                                              newValue,
                                              virtualItem.index,
                                              dayIndex,
                                              exerciseIndex,
                                              fieldName
                                            )
                                          }
                                          inputRef={(el) => {
                                            inputRefs.current[fieldKey] = el;
                                          }}
                                          style={{
                                            ...inputStyle,
                                          }}
                                          fieldNames={fieldNames}
                                          weekIndex={virtualItem.index}
                                          dayIndex={dayIndex}
                                          exerciseIndex={exerciseIndex}
                                          fieldName={fieldName}
                                          inputRefs={inputRefs}
                                          maxIndices={maxIndices}
                                          isSelected={
                                            !!(isSelectedCell || isEditingCell)
                                          }
                                          isEditing={!!isEditingCell}
                                          onClick={() =>
                                            handleCellClick(
                                              virtualItem.index,
                                              dayIndex,
                                              exerciseIndex,
                                              fieldName
                                            )
                                          }
                                          onBlurEditing={handleBlurEditing}
                                          onEnterEditMode={() =>
                                            setEditingCell({
                                              weekIndex: virtualItem.index,
                                              dayIndex,
                                              exerciseIndex,
                                              fieldName,
                                            })
                                          }
                                        />
                                      )}
                                    </StyledCell>
                                  );
                                })}
                                <StyledCell width={40}>
                                  <IconButton
                                    onClick={() =>
                                      handleDeleteExercise(
                                        virtualItem.index,
                                        dayIndex,
                                        exerciseIndex
                                      )
                                    }
                                    size="small"
                                  >
                                    <DeleteIcon fontSize="small" />
                                  </IconButton>
                                </StyledCell>
                              </StyledRow>
                            ))}
                            <Box
                              sx={{
                                display: "flex",
                                justifyContent: "left",
                                marginTop: "10px",
                              }}
                            >
                              <IconButton
                                onClick={() =>
                                  handleAddExercise(virtualItem.index, dayIndex)
                                }
                                color="primary"
                              >
                                <AddIcon />
                              </IconButton>
                            </Box>
                          </StyledTableContainer>
                        </React.Fragment>
                      ))}
                    </Box>
                  );
                })}
              </Box>
            </Box>
          </Box>
        </>
      )}
      <SaveNewVersionModal
        isOpenModal={openSaveVersionModal}
        handleCloseModal={() => setOpenSaveVersionModal(!openSaveVersionModal)}
        onSubmit={handleCreateNewVersion}
        isTemplate={selectedWorkoutProgram?.workoutProgramType === "Template"}
      />
      <SavingProgramModal
        isOpenModal={savingProgram}
        handleCloseModal={() => setSavingProgram(false)}
      />
      <CompleteAndShareModal
        isOpenModal={openCompleteAndShareModal}
        handleCloseModal={() =>
          setOpenCompleteAndShareModal(!openCompleteAndShareModal)
        }
        onSubmit={() => {
          handleCompleteWorkoutProgramAndShare();
        }}
        invoiceRequested={invoiceRequested}
        setInvoiceRequested={setInvoiceRequested}
        invoiceDueDate={invoiceDueDate}
        handleInvoiceRequestedDateChange={handleInvoiceRequestedDateChange}
      />
      <ModernProgramBuilderSettingsSidebar
        isDrawerOpen={isDrawerOpen}
        handleCloseDrawer={() => setIsDrawerOpen(false)}
        simplifiedView={simplifiedView}
        setSimplifiedView={setSimplifiedView}
        showNotes={showNotes}
        setShowNotes={setShowNotes}
        showLogs={showLogs}
        setShowLogs={setShowLogs}
        progressionView={progressionView}
        duplicateView={duplicateView}
        setDuplicateView={setDuplicateView}
        setProgressionView={setProgressionView}
        selectedWorkoutProgram={selectedWorkoutProgram}
      />
    </Box>
  );
};

export { ModernProgramBuilder };
