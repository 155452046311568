/* eslint-disable */

export const validationRules: {
  [key: string]: (value: any) => { isValid: boolean; cellErrorMessage: string };
} = {
  exerciseName: (value: any) => {
    if (value === "") {
      return { isValid: false, cellErrorMessage: "Exercise name is required." };
    } else {
      return { isValid: true, cellErrorMessage: "" };
    }
  },
  load: (value: any) => {
    if (value === "") {
      return { isValid: true, cellErrorMessage: "" };
    }

    // Patterns to match numbers with optional units
    const numberPattern = /^\d+(\.\d+)?$/; // e.g., "125", "125.5"
    const numberWithUnitPattern = /^\d+(\.\d+)?\s*(kg|kgs|lb|lbs)$/i; // e.g., "125kg", "125.5 kg"

    // Patterns to match ranges with optional units and spaces
    const rangePattern = /^\d+(\.\d+)?\s*-\s*\d+(\.\d+)?$/; // e.g., "125-130", "125.5 - 130.5"
    const rangeWithUnitsPattern =
      /^\d+(\.\d+)?\s*(kg|kgs|lb|lbs)?\s*-\s*\d+(\.\d+)?\s*(kg|kgs|lb|lbs)?$/i; // e.g., "125kg-130kg", "125 - 130 kg"

    if (
      numberPattern.test(value) ||
      numberWithUnitPattern.test(value) ||
      rangePattern.test(value) ||
      rangeWithUnitsPattern.test(value)
    ) {
      return { isValid: true, cellErrorMessage: "" };
    } else {
      return {
        isValid: false,
        cellErrorMessage:
          "Invalid weight value. Enter a number, range (e.g., 125 - 130), or include kg(s)/lb(s).",
      };
    }
  },
  sets: (value: any) => {
    const pattern = /^(\d+|\d+-\d+)$/; // Matches "10" or "8-12"
    if (pattern.test(value)) {
      return { isValid: true, cellErrorMessage: "" };
    } else {
      return {
        isValid: false,
        cellErrorMessage:
          "Sets must be a positive number or a range (e.g., 3 or 4-5).",
      };
    }
  },
  intensity: (value: any) => {
    if (value === "") {
      return { isValid: true, cellErrorMessage: "" };
    }

    // Patterns to match
    const numberPattern = /^\d+(\.\d+)?$/; // Matches numbers like "7" or "7.5"
    const rangePattern = /^\d+(\.\d+)?-\d+(\.\d+)?$/; // Matches ranges like "7-8" or "7.5-8.5"
    const numberWithRpeRirPattern = /^\d+(\.\d+)?\s*(rpe|rir)$/i; // Matches "7 rpe", "7.5 rpe", "7 rir", "7.5 rir"
    const rpeRirWithNumberPattern = /^(rpe|rir)\s*\d+(\.\d+)?$/i; // Matches "rpe 7", "rpe 7.5", "rir 7", "rir 7.5"
    const numberRangeWithRpeRirPattern =
      /^\d+(\.\d+)?-\d+(\.\d+)?\s*(rpe|rir)$/i; // Matches "7-8 rpe", "7.5-8.5 rpe", "7-8 rir", "7.5-8.5 rir"
    const rpeRirWithNumberRangePattern =
      /^(rpe|rir)\s*\d+(\.\d+)?-\d+(\.\d+)?$/i; // Matches "rpe 7-8", "rpe 7.5-8.5", "rir 7-8", "rir 7.5-8.5"
    const percentagePattern = /^\d+(\.\d+)?%$/; // Matches "75%" or "75.5%"
    const percentageRangePattern = /^\d+(\.\d+)?%?\s*-\s*\d+(\.\d+)?%$/; // Matches "75%-80%", "75.5% - 80.5%", or "50-60%"

    if (
      numberPattern.test(value) ||
      rangePattern.test(value) ||
      numberWithRpeRirPattern.test(value) ||
      rpeRirWithNumberPattern.test(value) ||
      numberRangeWithRpeRirPattern.test(value) ||
      rpeRirWithNumberRangePattern.test(value) ||
      percentagePattern.test(value) ||
      percentageRangePattern.test(value)
    ) {
      return { isValid: true, cellErrorMessage: "" };
    } else {
      return {
        isValid: false,
        cellErrorMessage:
          'Intensity must be a number, a number range (e.g., "7-8"), include "rpe"/"rir", or a percentage (e.g., "75%").',
      };
    }
  },
  supersetGroup: (value: any) => {
    //can be empty
    if (value === "") {
      return { isValid: true, cellErrorMessage: "" };
    }

    const pattern = /^[1-9]|10$/;
    if (pattern.test(value)) {
      return { isValid: true, cellErrorMessage: "" };
    } else {
      return {
        isValid: false,
        cellErrorMessage:
          "Superset group must be a number between 1 and 10 or empty.",
      };
    }
  },
  /**
   * Here are the rules for progression:
   * [Type] [Value] [Frequency]
   * Type: "rpe", or "1rm"
   * Value: A number or a range (e.g., "7" or "7-8")
   * Frequency: "e/w", "e/2w", "e/3w" etc. What this means is that the progression will happen every week, every 2 weeks, every 3 weeks etc.
   * Examples:
   * "rpe 0.5-1 e/w" - Progression of 0.5 to 1 RPE every week. When it is RPE we increase the RPE value.
   *
   * For 1RM type, we have different syntax: [Type] [1RM] [Value] [Frequency]
   * Examples:
   * "1rm 100kg 2.5% e/w" - We will add 2.5% each week
   */
  progression: (value: any) => {
    //can be empty
    if (value === "") {
      return { isValid: true, cellErrorMessage: "" };
    }

    const rpePattern = /^(rpe)\s*\d+(\.\d+)?(-\d+(\.\d+)?)?\s*(e\/w|e\/\d+w)$/i;
    const oneRmPattern =
      /^1rm\s*\d+(\.\d+)?(kg|lbs)\s*\d+(\.\d+)?%\s*(e\/w|e\/\d+w)$/i;

    if (rpePattern.test(value) || oneRmPattern.test(value)) {
      return { isValid: true, cellErrorMessage: "" };
    } else {
      return {
        isValid: false,
        cellErrorMessage:
          'Invalid progression. Enter a progression in the format "rpe 7 e/w" or "1rm 100kg 2.5% e/w".',
      };
    }
  },
};
