import { FC, useEffect, useState } from "react";
import { Button, Icon, Typography, Tab, useMediaQuery } from "@mui/material";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { Box } from "@mui/system";
import { useTranslation } from "react-i18next";
import { Colors } from "shared/themes";
import { sharedStyles } from "shared/themes/shared/styles";
import { useTypedSelector } from "shared/stores";
import { ProgramsContainer } from "./ui/ProgramsContainer";
import { ProgramTemplatesContainer } from "./ui/ProgramTemplatesContainer";
import { AddBaseWorkoutProgramModal } from "../../ModernProgramBuilder/AddBaseWorkoutProgramModal";
import { GenerateProgramModal } from "../../ModernProgramBuilder/GenerateProgramModal";

interface ProgramsProps {
  getUserIdForWorkoutCreation: (userId: number) => void;
  setCreateWorkoutUserId: React.Dispatch<React.SetStateAction<number | null>>;
}

const AdminPrograms: FC<ProgramsProps> = ({
  getUserIdForWorkoutCreation,
  setCreateWorkoutUserId,
}) => {
  const { t } = useTranslation();
  const [isOpenModal, setIsOpenModal] = useState(false);
  const handleToggleModal = () => setIsOpenModal(!isOpenModal);
  const matches = useMediaQuery("(min-width:900px)");
  const isOpenSidebar = useTypedSelector(
    (state) => state.sidebarState.isOpenSidebar
  );
  const showSidebar = matches && isOpenSidebar;

  const [openBaseProgramModal, setOpenBaseProgramModal] = useState(false);

  const [isTemplateProgram, setIsTemplateProgram] = useState(false);

  const [openGenerateProgramModal, setOpenGenerateProgramModal] =
    useState(false);

  const handleCloseGenerateProgramModal = () => {
    setOpenGenerateProgramModal(false);
  };

  const handleCloseBaseProgramModal = () => {
    setOpenBaseProgramModal(false);
  };

  const [tabValue, setTabValue] = useState("1");

  const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
    setTabValue(newValue);
  };

  useEffect(() => {
    setCreateWorkoutUserId(null);
  }, []);

  return (
    <Box
      sx={
        showSidebar
          ? sharedStyles.containers.sidebar.content
          : sharedStyles.containers.sidebar.contentFullscreen
      }
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "right",
          justifyContent: "space-between",
          width: "100%",
          height: "58px",
          mb: "20px",
        }}
      >
        <Typography
          sx={{
            fontFamily: "Inter",
            fontStyle: "normal",
            fontWeight: "600",
            fontSize: "24px",
            lineHeight: "140%",
            color: `${Colors.gray[900]}`,
            textTransform: "none",
          }}
        >
          {t("programs.title")}
        </Typography>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            width: "auto",
            height: "auto",
            "& > *:not(:last-child)": {
              marginRight: "16px",
            },
          }}
        >
          <div id="add-template-program">
            <Button
              variant="outlined"
              color="primary"
              sx={{
                fontFamily: "Inter",
                fontStyle: "normal",
                fontWeight: "500",
                fontSize: "14px",
                lineHeight: "24px",
                letterSpacing: "0.4px",
                textTransform: "none",
              }}
              onClick={() => {
                setIsTemplateProgram(true);
                setOpenBaseProgramModal(true);
              }}
            >
              <Icon
                sx={{
                  marginRight: "8px",
                }}
              >
                add_icon
              </Icon>
              Create template
            </Button>
          </div>
          <div id="generate-program">
            <Button
              variant="outlined"
              color="primary"
              sx={{
                fontFamily: "Inter",
                fontStyle: "normal",
                fontWeight: "500",
                fontSize: "14px",
                lineHeight: "24px",
                letterSpacing: "0.4px",
                textTransform: "none",
              }}
              onClick={() => {
                setIsTemplateProgram(false);
                setOpenGenerateProgramModal(true);
              }}
            >
              <Icon
                sx={{
                  marginRight: "8px",
                }}
              >
                auto_awesome
              </Icon>
              Generate program
            </Button>
          </div>
          <div id="add-workout-program">
            <Button
              variant="contained"
              color="primary"
              sx={{
                fontFamily: "Inter",
                fontStyle: "normal",
                fontWeight: "500",
                fontSize: "14px",
                lineHeight: "24px",
                letterSpacing: "0.4px",
                color: `${Colors.gray[100]}`,
                textTransform: "none",
              }}
              onClick={() => {
                setIsTemplateProgram(false);
                setOpenBaseProgramModal(true);
              }}
            >
              <Icon
                sx={{
                  marginRight: "8px",
                }}
              >
                add_icon
              </Icon>
              Create program
            </Button>
          </div>
        </Box>
      </Box>
      <TabContext value={tabValue}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <TabList onChange={handleTabChange} aria-label="Tabs">
            <Tab label="Programs" value="1" />
            <Tab label="Templates" value="2" />
          </TabList>
        </Box>
        <TabPanel value="1">
          <ProgramsContainer
            getUserIdForWorkoutCreation={getUserIdForWorkoutCreation}
          />
        </TabPanel>
        <TabPanel value="2">
          <ProgramTemplatesContainer />
        </TabPanel>
      </TabContext>
      <AddBaseWorkoutProgramModal
        isOpenModal={openBaseProgramModal}
        handleCloseModal={handleCloseBaseProgramModal}
        isTemplateProgram={isTemplateProgram}
      />
      <GenerateProgramModal
        isOpenModal={openGenerateProgramModal}
        handleCloseModal={handleCloseGenerateProgramModal}
      />
    </Box>
  );
};

export { AdminPrograms };
