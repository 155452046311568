import { FC, useState } from "react";
import {
  Box,
  MenuItem,
  Select,
  TextField,
  Typography,
  Grid,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { useFormik } from "formik";
import * as Yup from "yup";
import { LoadingButton } from "@mui/lab";
import { useTypedSelector } from "shared/stores";
import { userSelectors } from "shared/stores/user";
import { AnyType } from "shared/helpers";
import {
  useGetUserProfileItemQuery,
  UserProfileItemResponse,
  useUpdateUserProfileMutation,
  useGetUsersQuery,
} from "shared/api";
import { Colors } from "shared/themes";

interface UpdateUserFormProps {
  currentUserData?: UserProfileItemResponse;
  toggleSuccessfulUpdateModal: () => void;
  isAthlete?: boolean;
}

interface SubmitValues {
  id?: number;
  email?: string;
  firstName?: string;
  lastName?: string;
  myFitnessPalUsername?: string;
  dateOfBirth?: string;
  phoneNumber?: string;
  userRole?: string;
  userStatus?: string;
  userCategory?: string;
  assignedFitnessProfessionalId?: number;
  userLiftingProfileType?: string;
  swapExercisesEnabled?: boolean;
}

const UserProfileSchema = Yup.object().shape({
  firstName: Yup.string().min(2, "Min 2").required("This field is required"),
  lastName: Yup.string().min(2, "Min 2").required("This field is required"),
});

const UpdateUserForm: FC<UpdateUserFormProps> = ({
  currentUserData,
  toggleSuccessfulUpdateModal,
  isAthlete = false,
}) => {
  const { t } = useTranslation();
  const isAdminOrCoach = useTypedSelector(userSelectors.isAdminOrCoach);
  const isAdmin = useTypedSelector(userSelectors.isAdmin);

  const [errorMessage, setErrorMessage] = useState("");
  const [updateUserProfile, { isLoading }] = useUpdateUserProfileMutation();
  const { data: updatedUser, refetch } = useGetUserProfileItemQuery(
    currentUserData!.id
  );

  const {
    data: fitnessProfessionals,
    isLoading: isLoadingFitnessProfessionals,
  } = useGetUsersQuery({
    count: 0,
    page: 0,
    userRole: "admin",
    orderByName: true,
  });

  const onSubmit = async (params: SubmitValues) => {
    try {
      await updateUserProfile({
        ...params,
        swapExercisesEnabled: `${params.swapExercisesEnabled}`,
      }).unwrap();
      toggleSuccessfulUpdateModal();
    } catch (error: AnyType) {
      setErrorMessage(error?.data?.message);
    } finally {
      await refetch();
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      resetForm();
    }
  };

  const {
    values,
    handleSubmit,
    handleChange,
    handleBlur,
    errors,
    setFieldValue,
    resetForm,
  } = useFormik<SubmitValues>({
    initialValues: {
      id: currentUserData?.id,
      firstName: currentUserData?.firstName,
      lastName: currentUserData?.lastName,
      phoneNumber: currentUserData?.phoneNumber,
      email: currentUserData?.email,
      userStatus: currentUserData?.userStatus,
      userCategory: currentUserData?.userCategory,
      userRole: currentUserData?.userRole,
      dateOfBirth: currentUserData?.dateOfBirth,
      assignedFitnessProfessionalId:
        currentUserData?.assignedFitnessProfessionalId,
      userLiftingProfileType:
        currentUserData?.userLiftingProfileType?.liftingProfileType,
      myFitnessPalUsername: currentUserData?.myFitnessPalUsername,
      swapExercisesEnabled: currentUserData?.swapExercisesEnabled,
    },
    enableReinitialize: true,
    validationSchema: UserProfileSchema,
    onSubmit: (params) => {
      onSubmit(params);
    },
  });

  const checkBeforeSubmit =
    values.dateOfBirth === currentUserData?.dateOfBirth &&
    values.email === currentUserData?.email &&
    values.firstName === currentUserData?.firstName &&
    values.lastName === currentUserData?.lastName &&
    values.phoneNumber === currentUserData?.phoneNumber &&
    values.userRole === currentUserData?.userRole &&
    values.userStatus === currentUserData?.userStatus &&
    values.userCategory === currentUserData?.userCategory &&
    values.assignedFitnessProfessionalId ===
      currentUserData?.assignedFitnessProfessionalId &&
    values.userLiftingProfileType ===
      currentUserData?.userLiftingProfileType?.liftingProfileType &&
    values.myFitnessPalUsername === currentUserData?.myFitnessPalUsername &&
    values.swapExercisesEnabled === currentUserData?.swapExercisesEnabled;

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={12} lg={12}>
        <Box
          sx={{
            bgcolor: `${Colors.gray[100]}`,
            mt: "20px",
            padding: "20px",
            height: "auto",
            width: "100%",
          }}
        >
          <Grid container spacing={2}>
            {/* First Name */}
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                size="small"
                type="text"
                disabled={!isAdminOrCoach}
                label={t("user-profile.first-name")}
                value={values.firstName}
                onBlur={handleBlur("firstName")}
                onChange={handleChange("firstName")}
                error={!!errors.firstName}
                helperText={errors.firstName}
                sx={{ mb: 2 }}
              />
            </Grid>

            {/* Last Name */}
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                size="small"
                type="text"
                label={t("user-profile.last-name")}
                disabled={!isAdminOrCoach}
                error={!!errors.lastName}
                helperText={errors.lastName}
                value={values.lastName}
                onBlur={handleBlur("lastName")}
                onChange={handleChange("lastName")}
                sx={{ mb: 2 }}
              />
            </Grid>

            {/* Email */}
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                size="small"
                type="email"
                label={t("user-profile.email")}
                value={values.email}
                disabled
                onBlur={handleBlur("email")}
                onChange={handleChange("email")}
                sx={{ mb: 2 }}
              />
            </Grid>

            {/* Phone Number */}
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                size="small"
                type="text"
                label={t("user-profile.phone")}
                value={values.phoneNumber}
                onBlur={handleBlur("phoneNumber")}
                onChange={handleChange("phoneNumber")}
                sx={{ mb: 2 }}
              />
            </Grid>

            {/* User Status */}
            {isAdminOrCoach && (
              <Grid item xs={12} md={6}>
                <Typography sx={{ mb: 1 }}>
                  {t("user-profile.user-status")}
                </Typography>
                <Select
                  fullWidth
                  value={values.userStatus || currentUserData?.userStatus}
                  onBlur={handleBlur("userStatus")}
                  onChange={(e) => setFieldValue("userStatus", e.target.value)}
                  size="small"
                  sx={{ mb: 2 }}
                >
                  <MenuItem value="Active">Active</MenuItem>
                  <MenuItem value="Inactive">Inactive</MenuItem>
                </Select>
              </Grid>
            )}

            {/* User Category */}
            {isAdminOrCoach && (
              <Grid item xs={12} md={6}>
                <Typography sx={{ mb: 1 }}>
                  {t("user-profile.user-category")}
                </Typography>
                <Select
                  fullWidth
                  value={values.userCategory || currentUserData?.userCategory}
                  onBlur={handleBlur("userCategory")}
                  onChange={(e) =>
                    setFieldValue("userCategory", e.target.value)
                  }
                  size="small"
                  sx={{ mb: 2 }}
                >
                  <MenuItem value="Undefined">None</MenuItem>
                  <MenuItem value="Online">Online</MenuItem>
                  <MenuItem value="InPerson">In Person</MenuItem>
                  <MenuItem value="Hybrid">Hybrid</MenuItem>
                  <MenuItem value="NonCoaching">Non Coaching</MenuItem>
                </Select>
              </Grid>
            )}

            {/* User Lifting Profile Type */}
            {isAdminOrCoach && (
              <Grid item xs={12} md={6}>
                <Typography sx={{ mb: 1 }}>
                  {t("user-profile.user-lifting-profile")}
                </Typography>
                <Select
                  fullWidth
                  value={
                    values.userLiftingProfileType ||
                    currentUserData?.userLiftingProfileType?.liftingProfileType
                  }
                  onBlur={handleBlur("userLiftingProfileType")}
                  onChange={(e) =>
                    setFieldValue("userLiftingProfileType", e.target.value)
                  }
                  size="small"
                  sx={{ mb: 2 }}
                >
                  <MenuItem value="Undefined">
                    <em>None</em>
                  </MenuItem>
                  <MenuItem value="Powerlifting">Powerlifting</MenuItem>
                  <MenuItem value="OlympicWeightlifting">
                    Olympic Weightlifting
                  </MenuItem>
                  <MenuItem value="Bodybuilding">Bodybuilding</MenuItem>
                  <MenuItem value="Crossfit">Crossfit</MenuItem>
                  <MenuItem value="Strongman">Strongman</MenuItem>
                  <MenuItem value="Powerbuilding">Powerbuilding</MenuItem>
                  <MenuItem value="Calisthenics">Calisthenics</MenuItem>
                  <MenuItem value="GeneralFitness">General Fitness</MenuItem>
                </Select>
              </Grid>
            )}

            {/* Swap Exercises Enabled */}
            {isAdminOrCoach && (
              <Grid item xs={12} md={6}>
                <Typography sx={{ mb: 1 }}>
                  {t("user-profile.swap-exercises-enabled")}
                </Typography>
                <Select
                  fullWidth
                  value={
                    values.swapExercisesEnabled ||
                    currentUserData?.swapExercisesEnabled
                  }
                  onBlur={handleBlur("swapExercisesEnabled")}
                  onChange={(e) =>
                    setFieldValue("swapExercisesEnabled", e.target.value)
                  }
                  size="small"
                  sx={{ mb: 2 }}
                >
                  <MenuItem value="true">Yes</MenuItem>
                  <MenuItem value="false">No</MenuItem>
                </Select>
              </Grid>
            )}

            {/* Assigned Fitness Professional */}
            {isAdmin &&
              !isLoadingFitnessProfessionals &&
              fitnessProfessionals && (
                <Grid item xs={12}>
                  <Typography sx={{ mb: 1 }}>
                    {t("user-profile.coach")}
                  </Typography>
                  <Select
                    fullWidth
                    value={
                      values.assignedFitnessProfessionalId ||
                      currentUserData?.assignedFitnessProfessionalId
                    }
                    onChange={(e) =>
                      setFieldValue(
                        "assignedFitnessProfessionalId",
                        e.target.value
                      )
                    }
                    size="small"
                    sx={{ mb: 2 }}
                  >
                    {fitnessProfessionals.map((user) => (
                      <MenuItem
                        key={user.id}
                        value={user.id}
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                        <Typography
                          sx={{
                            fontWeight: "bold",
                            fontSize: "1.2rem",
                          }}
                        >
                          {`${user.firstName} ${user.lastName}`}
                        </Typography>
                        <Typography variant="body2" component="div">
                          {user.email}
                        </Typography>
                      </MenuItem>
                    ))}
                  </Select>
                </Grid>
              )}

            {/* Submit Button */}
            <Grid item xs={12}>
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  mt: 2,
                }}
              >
                <LoadingButton
                  loading={isLoading}
                  onClick={() => {
                    handleSubmit();
                  }}
                  disabled={checkBeforeSubmit}
                  color="primary"
                  variant="contained"
                  sx={{
                    width: "25%",
                    textTransform: "none",
                  }}
                >
                  {t("user-profile.update-btn")}
                </LoadingButton>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Grid>
    </Grid>
  );
};

export { UpdateUserForm };
