import { FC, useEffect, useState } from "react";
import { Box } from "@mui/system";
import { Typography, Grid, Tab } from "@mui/material";
import { useParams, useNavigate } from "react-router-dom";
import { useGetUserProfileItemQuery } from "shared/api";
import { Colors } from "shared/themes";
import { IdenticonAvatar } from "shared/ui/IdenticonAvatar";
import { GridStatusItem } from "components/Grid/GridStatusItem";

// Import Tabs components
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";

// Import other components used in tabs
import { ClientInsights } from "pages/Users/ClientInsights";
import { ClientFeedbackSummary } from "pages/Users/ClientFeedbackSummary";
import { Routes } from "shared/routers";
import { UpdateUserForm } from "./ui/UpdateUserForm";
import { SuccessfulUpdateModal } from "./ui/modals/SuccessfulUpdateModal";
import { UserProfileSkeleton } from "./ui/skeletons/UserProfileSkeleton";

interface AthleteUserProfileProps {
  closeSidebar: () => void;
}

const AthleteUserProfile: FC<AthleteUserProfileProps> = ({ closeSidebar }) => {
  const params = useParams();
  const navigate = useNavigate();
  const [tabValue, setTabValue] = useState(params.tabId || "1");

  useEffect(() => {
    if (params.tabId !== tabValue) {
      setTabValue(params.tabId || "1");
    }
  }, [params.tabId]);

  const { data: currentUserData, isLoading: isLoadingCurrentUserData } =
    useGetUserProfileItemQuery(Number(params.userId));

  const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
    setTabValue(newValue);
    if (currentUserData?.id)
      navigate(`${Routes.profile.url}/${currentUserData.id}/${newValue}`);
  };

  const [successfulUpdateModal, setSuccessfulUpdateModal] = useState(false);
  const toggleSuccessfulUpdateModal = () => {
    setSuccessfulUpdateModal((prev) => !prev);
  };

  // Wait for currentUserData to be loaded before rendering components that depend on it
  if (isLoadingCurrentUserData || !currentUserData) {
    return <UserProfileSkeleton />;
  }

  return (
    <Box
      sx={{
        overflow: "hidden",
        width: "100%",
        height: "100vh",
      }}
    >
      <SuccessfulUpdateModal
        successfulUpdateModal={successfulUpdateModal}
        toggleModal={toggleSuccessfulUpdateModal}
      />
      <Box
        sx={{
          width: "100%",
          height: "100%",
          display: "flex",
          padding: "10px 20px 27px 24px",
          flexDirection: "column",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <Box>
            <IdenticonAvatar
              sizeValue={40}
              seedValue={currentUserData.id.toString()}
              profileImageUrl={currentUserData.profileImageUrl || ""}
              allowUpload
            />
          </Box>
          <Typography
            sx={{
              fontFamily: "Inter",
              fontStyle: "normal",
              fontWeight: "600",
              fontSize: "24px",
              lineHeight: "140%",
              textTransform: "none",
              color: `${Colors.gray[900]}`,
              mb: "16px",
              mt: "26px",
              pl: 1,
            }}
          >
            {currentUserData.firstName} {currentUserData.lastName}
          </Typography>
        </Box>
        <Box
          sx={{
            height: "10%",
            width: "100px",
            mb: "16px",
          }}
        >
          <GridStatusItem status={currentUserData.userStatus} />
        </Box>
        {/* Add TabContext here */}
        <TabContext value={tabValue}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <TabList onChange={handleTabChange} aria-label="Tabs">
              <Tab label="Overview" value="1" />
            </TabList>
          </Box>
          <Grid>
            <TabPanel value="1">
              <Grid item xs={12} md={12} lg={12}>
                <Grid container spacing={2}>
                  <ClientInsights selectedUserId={currentUserData.id} />
                </Grid>
                <Grid container spacing={2} mt={2}>
                  <ClientFeedbackSummary selectedUserId={currentUserData.id} />
                </Grid>
                <UpdateUserForm
                  currentUserData={currentUserData}
                  toggleSuccessfulUpdateModal={toggleSuccessfulUpdateModal}
                  isAthlete
                />
              </Grid>
            </TabPanel>
          </Grid>
        </TabContext>
      </Box>
    </Box>
  );
};

export { AthleteUserProfile };
